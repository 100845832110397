import { Grid } from '@mui/material';
import { useSmartList } from './hook';
import CustomDropdown from '../../common/fields/CustomDropdown';
import AddUpdatePlayer from '../playerdatabase/component/AddUpdatePlayer';
import { PlayerSelectedTabEnum } from '../playerdatabase/type';
import AddTimelineNote from './component/AddTimelineNote';
import AddUpdateContract from '../playerdatabase/component/AddUpdateContract';
import SmartPlayer from './component/SmartPlayer';
import BulkContractModal from './component/BulkContractModal';

const SmartList = () => {
  const {
    selectedTeam,
    selectedPlayerForEdit,
    playerListInfo,
    setSelectedPlayerForEdit,
    setSelectedTeam,
    playerListOption,
    smartListData,
    isLoading,
    refreshData,
    teamOptions,
    selectedPlayerForAddNote,
    setSelectedPlayerForAddNote,
    addContract,
    setHandleAddContract,
    openBulkContractModal,
    setOpenBulkContractModal,
  } = useSmartList();
  return (
    <Grid container mt={'20px'}>
      <Grid item xs={12} mb={'10px'}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={12} md={4}>
            <CustomDropdown
              label='Team'
              options={teamOptions}
              fullWidth
              variant='outlined'
              onOptionChange={setSelectedTeam}
              value={selectedTeam}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SmartPlayer
          summaryList={smartListData.summaryData}
          onEditClick={(playerId) => {
            setSelectedPlayerForEdit(
              playerListInfo.find((item) => item.id.toString() === playerId.toString()),
            );
          }}
          setOpenBulkContractModal={setOpenBulkContractModal}
          loading={isLoading}
          playerListOption={playerListOption}
          onAddNoteClick={(playerId) => {
            setSelectedPlayerForAddNote(
              playerListInfo.find((item) => item.id.toString() === playerId.toString()),
            );
          }}
          onYearAddContractClick={(contractInfo) => {
            setHandleAddContract(contractInfo);
          }}
          playerList={smartListData.playerList}
          years={smartListData.columns}
        />
      </Grid>
      {selectedPlayerForEdit && (
        <AddUpdatePlayer
          refreshList={refreshData}
          handleClose={() => {
            setSelectedPlayerForEdit(undefined);
            refreshData();
          }}
          initialTab={PlayerSelectedTabEnum.CONTRACT}
          playerData={selectedPlayerForEdit}
          selectedTeam={selectedTeam}
        />
      )}
      {selectedPlayerForAddNote && (
        <AddTimelineNote
          playerId={selectedPlayerForAddNote.id}
          handleClose={() => {
            setSelectedPlayerForAddNote(undefined);
          }}
        />
      )}
      {addContract && (
        <AddUpdateContract
          handleClose={() => {
            setHandleAddContract(undefined);
          }}
          playerId={addContract.playerId}
          startSeason={addContract.selectedYear}
          refreshList={() => {
            setHandleAddContract(undefined);
            refreshData();
          }}
          seasonList={addContract.contracts.map((item) => ({
            endSeason: new Date(item.endDate).getFullYear().toString(),
            startSeason: new Date(item.startDate).getFullYear().toString(),
          }))}
          selectedTeam={selectedTeam}
        />
      )}

      {openBulkContractModal && (
        <BulkContractModal
          teamOptions={teamOptions}
          selectedTeamName={selectedTeam}
          playerListOption={playerListOption}
          playerListInfo={playerListInfo}
          handleClose={() => setOpenBulkContractModal(false)}
          handleRefreshList={() => {
            refreshData();
            setOpenBulkContractModal(false);
          }}
        />
      )}
    </Grid>
  );
};

export default SmartList;
