/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCurrentProject } from '../../store/header/selector';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  useCreateAcademyBidMutation,
  useCreateAddTradeMutation,
  useCreateDraftNightMutation,
  useCreateFatherSonBidMutation,
  useCreateFreeAgentCompensationMutation,
  useCreateManualInsertMoveMutation,
  useCreateManualMoveMutation,
  useCreateNewTransactionMutation,
  useCreateNgaBidMutation,
  useCreatePriorityPickMutation,
  useGetAcademyTransactionMutation,
  useGetAddTradePopupTransactionQuery,
  useGetAllPickQuery,
  useGetCompleteTransactionImpactDataMutation,
  useGetFAPickTypeQuery,
  useGetFamilyTransactionMutation,
  useGetNgaTransactionSumMutation,
  useGetPickTypeQuery,
  useGetPlayerQuery,
  useGetPriorityPickTypeQuery,
  useGetRoundsQuery,
  useGetShowTeamQuery,
  useGetTransactionImpactPriorityPickMutation,
  useLazyDeleteUnusablePicksQuery,
  useLazyFetchRosterSpotsQuery,
  useLazyFetchTradeOfferStatusQuery,
  useLazyFetchTradeOfferTypeQuery,
  useLazyGetAllScenarioPickQuery,
  useLazyGetTradesQuery,
  useManualPickEditApiMutation,
  useUpdateRosterSpotMutation,
  useUpdateTradeOfferTransactionMutation,
} from '../../store/service';
import {
  transformAcademyAllPickDataOption,
  transformAcademyPickDataOption,
  transformAcademyPlayerDropdownOption,
  transformAcademyTeamNameDropdownOption,
  transformFaPickDataOption,
  transformFamilyTransactionData,
  transformImpactPriorityPick,
  transformNgaBidLine,
  transformPickListDropdown,
  transformRoundDropdownOption,
  transformTradeTypeOption,
  transformTransactionImpact,
  trasnformTradeStatusOption,
} from './util';
import { useEffect, useMemo, useState } from 'react';
import {
  AcademyBidMatchType,
  CompletedTradeType,
  DraftNightSelectionType,
  FatherBidMatchType,
  FreeAgentCompensationType,
  ManualPickEditType,
  ManualPickInsertType,
  ManualPickMoveType,
  NgaBidMatchType,
  PriorityPickType,
  TradeFormType,
  TransactionImpactPriorityPick,
  TransactionImpactType,
} from './type';
import {
  setSelectedTransactionModal,
  setUploadCsvSelectedTransactionTypeModal,
} from '../../store/header';
import { OptionType } from '../../common/fields/type';
import {
  reverseAcademyBidTransformData,
  reverseCompletedTradeTransformData,
  reverseDraftNightTransformData,
  reverseFatherBidTransformData,
  reverseFreeAgentTransformData,
  reverseManualInsertTransformData,
  reverseManualMoveTransformData,
  reverseManualTransformData,
  reverseNgaBidTransformData,
  reversePriorityPickTransformData,
  reverseTradeOfferTransformData,
} from '../../store/service/util';
import { transformFlagTooltip } from '../header/util';
import { TeamInfo, YearData } from '../project/type';
import { toast } from 'react-toastify';
import { transformRosterData } from '../project/util';
import { isEqual } from 'lodash';
import { PlanningTradeOfferType } from '../planner/type';
import { useGetUserDetail } from '../../store/auth/selector';
import { useCreateScenarioFreeAgentCompensationMutation } from '../../store/service/scenarioservice';
import { ScenarioTradeType } from '../scenarioplanning/type';

export const useAcademyBidMatch = () => {
  const currentProject = useAppSelector(useCurrentProject);
  const { data: showTeamData } = useGetShowTeamQuery('');
  const { data: playerData } = useGetPlayerQuery(currentProject);
  const { data: pickData } = useGetPickTypeQuery('');
  const { data: allPicksData } = useGetAllPickQuery(parseInt(currentProject));

  return {
    teamDataDropdown: transformAcademyTeamNameDropdownOption(showTeamData?.data),
    playerDataDropdown: transformAcademyPlayerDropdownOption(playerData),
    pickDataDropdown: transformAcademyPickDataOption(pickData?.Picktype),
    allPickDataDropdown: transformAcademyAllPickDataOption(allPicksData),
    currentProject: parseInt(currentProject),
  };
};

export const useAcademyFormBidMatch = (data: AcademyBidMatchType, projectId: number) => {
  const [getAcademyTransaction, { isLoading, data: academyData }] =
    useGetAcademyTransactionMutation();

  useEffect(() => {
    if (data.academyPlayer && data.bidPick && data.playerBelongsTo) {
      getAcademyTransaction({
        academy_team: data.playerBelongsTo,
        pickid: data.bidPick,
        playerid: data.academyPlayer,
        projectId: projectId,
      });
    }
  }, [data.academyPlayer, data.bidPick, data.playerBelongsTo, getAcademyTransaction, projectId]);

  return {
    isLoading,
    transactionInfo: transformFamilyTransactionData(academyData),
  };
};

export const useAcademyUpdateBidMatch = () => {
  const [createAcademyBid, { isLoading: academyBidLoading }] = useCreateAcademyBidMutation();
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector(useCurrentProject);
  const { userInfo } = useAppSelector(useGetUserDetail);

  const submitData = (value: AcademyBidMatchType) => {
    createAcademyBid({
      data: value,
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Academy Bid',
              payload: reverseAcademyBidTransformData(value),
            }),
          );
      }
    });
  };
  return {
    submitData,
    academyBidLoading,
  };
};

export const useFatherSonBidMatch = () => {
  const [createFatherBid, { isLoading: fatherBidLoading }] = useCreateFatherSonBidMutation();
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector(useCurrentProject);
  const { userInfo } = useAppSelector(useGetUserDetail);

  const submitData = (value: FatherBidMatchType) => {
    createFatherBid({
      data: value,
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Father Son Bid',
              payload: reverseFatherBidTransformData(value),
            }),
          );
      }
    });
  };
  return {
    submitData,
    fatherBidLoading,
  };
};

export const usePriorityPickCreate = (allPickDataDropdown: OptionType[]) => {
  const [createPriorityPick, { isLoading }] = useCreatePriorityPickMutation();
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector(useCurrentProject);
  const { userInfo } = useAppSelector(useGetUserDetail);

  const submitData = (value: PriorityPickType) => {
    createPriorityPick({
      data: {
        ...value,
        uniquePick:
          allPickDataDropdown.find((item) => item.value === value.pickAligned)!.unique || '',
      },
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Priority Pick',
              payload: reversePriorityPickTransformData(
                {
                  ...value,
                  uniquePick:
                    allPickDataDropdown.find((item) => item.value === value.pickAligned)!.unique ||
                    '',
                },
                currentProject,
              ),
            }),
          );
      }
    });
  };
  return {
    submitData,
    isLoading,
  };
};

export const useTransactionImpact = (
  projectId: string,
  values: PriorityPickType,
  allPickDataDropdown: OptionType[],
) => {
  const [getTransaction, { data, isLoading }] = useGetTransactionImpactPriorityPickMutation();

  useEffect(() => {
    values && getTransactionImpact();
  }, [values]);

  const getTransactionImpact = () => {
    if (
      values.pickAligned &&
      values.pickInstruction &&
      values.reason &&
      values.teamPriorityPick &&
      values.typePriorityPick
    ) {
      getTransaction({
        data: {
          ...values,
          uniquePick:
            allPickDataDropdown.find((item) => item.value === values.pickAligned)!.unique || '',
        },
        projectId: projectId,
      });
    }
  };

  const impactData: TransactionImpactPriorityPick = useMemo(
    () => data && transformImpactPriorityPick(data),
    [data],
  );

  return {
    isLoading,
    impactData,
  };
};

export const useFreeAgentCompensation = (
  allPickDataDropdown: OptionType[],
  scenarioTradeInfo?: ScenarioTradeType,
  refreshTrade?: () => void,
) => {
  const [createFreeAgent, { isLoading }] = useCreateFreeAgentCompensationMutation();
  const [createScenarioFreeAgent, { isLoading: scenarioTradeCreateLoading }] =
    useCreateScenarioFreeAgentCompensationMutation();
  const [getAllScenarioPicks, { data: allScenarioPicksData }] = useLazyGetAllScenarioPickQuery();

  useEffect(() => {
    scenarioTradeInfo &&
      getAllScenarioPicks({
        projectId: currentProject,
        scenarioId: scenarioTradeInfo.scenarioId,
        transactionNo: (scenarioTradeInfo.transactionLength - 1).toString(),
      });
  }, []);

  const allScenarioPickDropdown = useMemo(
    () => transformAcademyAllPickDataOption(allScenarioPicksData),
    [allScenarioPicksData],
  );
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector(useCurrentProject);
  const { userInfo } = useAppSelector(useGetUserDetail);
  const submitData = (value: FreeAgentCompensationType) => {
    if (scenarioTradeInfo) {
      createScenarioFreeAgent({
        data: {
          ...value,
          uniquePick:
            (scenarioTradeInfo ? allScenarioPickDropdown : allPickDataDropdown).find(
              (item) => item.value === value.compensationAligned,
            )!.unique || '',
        },
        projectId: currentProject,
        scenarioInfo: {
          ...scenarioTradeInfo,
          transactionLength: scenarioTradeInfo.transactionLength - 1,
        },
      }).then((res: any) => {
        if (!res.error) {
          refreshTrade && refreshTrade();
        }
      });
      return;
    }

    createFreeAgent({
      data: {
        ...value,
        uniquePick:
          (scenarioTradeInfo ? allScenarioPickDropdown : allPickDataDropdown).find(
            (item) => item.value === value.compensationAligned,
          )!.unique || '',
      },
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Free Agent Compensation',
              payload: reverseFreeAgentTransformData({
                ...value,
                uniquePick:
                  (scenarioTradeInfo ? allScenarioPickDropdown : allPickDataDropdown).find(
                    (item) => item.value === value.compensationAligned,
                  )!.unique || '',
              }),
            }),
          );
      }
    });
  };
  return {
    submitData,
    allScenarioPickDropdown,
    isLoading: scenarioTradeInfo ? scenarioTradeCreateLoading : isLoading,
  };
};

export const useFamilyFormBidMatch = (data: FatherBidMatchType, projectId: number) => {
  const [getAcademyTransaction, { isLoading, data: academyData }] =
    useGetFamilyTransactionMutation();

  useEffect(() => {
    if (data.fatherSonBelongTo && data.fatherSonPlayers && data.pickOfBid) {
      getAcademyTransaction({
        fs_team: data.fatherSonBelongTo,
        pickid: data.pickOfBid,
        playerid: data.fatherSonPlayers,
        projectId: projectId,
      });
    }
  }, [
    data.fatherSonBelongTo,
    data.fatherSonPlayers,
    data.pickOfBid,
    getAcademyTransaction,
    projectId,
  ]);

  return {
    isLoading,
    transactionInfo: transformFamilyTransactionData(academyData),
  };
};

export const useCompleteTrade = (
  isTradeAnalysis?: boolean,
  refreshTrade?: () => void,
  editTrade?: PlanningTradeOfferType,
) => {
  const currentProject = useAppSelector(useCurrentProject);
  const { data: showTeamData } = useGetShowTeamQuery('');
  const dispatch = useAppDispatch();
  const [team1PickDropdown, setTeam1PickDropdown] = useState<OptionType[]>([]);
  const [team2PickDropdown, setTeam2PickDropdown] = useState<OptionType[]>([]);
  const [getStatusOption, { data: statusData }] = useLazyFetchTradeOfferStatusQuery();
  const [getOfferTypeOption, { data: offersTypeData }] = useLazyFetchTradeOfferTypeQuery();
  const [createTrade, { isLoading }] = useCreateAddTradeMutation();
  const [updateTrade, { isLoading: updateTradeLoading }] = useUpdateTradeOfferTransactionMutation();

  const [createNewTransaction, { isLoading: createNewTransactionLoading }] =
    useCreateNewTransactionMutation();
  const { isLoading: addTradePopupLoading, data } =
    useGetAddTradePopupTransactionQuery(currentProject);

  useEffect(() => {
    if (isTradeAnalysis) {
      getStatusOption('');
      getOfferTypeOption('');
    }
  }, []);

  const tradeStatusOption = useMemo(() => trasnformTradeStatusOption(statusData), [statusData]);
  const tradeTypeOption = useMemo(() => transformTradeTypeOption(offersTypeData), [offersTypeData]);
  const { userInfo } = useAppSelector(useGetUserDetail);
  const submitData = (data: CompletedTradeType) => {
    if (typeof editTrade === 'object') {
      updateTrade({
        data: reverseTradeOfferTransformData(data, team1PickDropdown, team2PickDropdown),
        projectId: currentProject,
        tradeId: editTrade.id.toString(),
      }).then((res: any) => {
        if (!res.error) {
          refreshTrade && refreshTrade();
        }
      });
      return;
    }
    if (isTradeAnalysis) {
      createNewTransaction({
        data: reverseTradeOfferTransformData(data, team1PickDropdown, team2PickDropdown),
        projectId: currentProject,
      }).then((res: any) => {
        if (!res.error) {
          refreshTrade && refreshTrade();
        }
      });
      return;
    }

    createTrade({
      data: reverseCompletedTradeTransformData(data, team1PickDropdown, team2PickDropdown),
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Trade',
              payload: reverseCompletedTradeTransformData(
                data,
                team1PickDropdown,
                team2PickDropdown,
              ),
            }),
          );
      }
    });
  };

  return {
    teamDataDropdown: transformAcademyTeamNameDropdownOption(showTeamData?.data),
    currentProject,
    submitData,
    isLoading,
    team1PickDropdown,
    setTeam1PickDropdown,
    team2PickDropdown,
    setTeam2PickDropdown,
    addTradeData: transformFlagTooltip(data),
    addTradePopupLoading,
    createNewTransactionLoading,
    updateTradeLoading,
    tradeStatusOption,
    tradeTypeOption,
  };
};

export const useCompleteFormTrade = (
  values: TradeFormType,
  projectId: string,
  setTeam1Dropdown: (option: OptionType[]) => void,
  setTeam2Dropdown: (option: OptionType[]) => void,
  team1Dropdown: OptionType[],
  team2Dropdown: OptionType[],
) => {
  const [getTradeDropdown, { data: team1Data }] = useLazyGetTradesQuery();
  const [getTeam2TradeDropdown, { data: team2Data }] = useLazyGetTradesQuery();
  const [transactionImpact, setTransactionImpact] = useState<TransactionImpactType | null>(null);
  const [getTransactinImpact, { data: transactionData }] =
    useGetCompleteTransactionImpactDataMutation();

  useEffect(() => {
    values.team1.name &&
      getTradeDropdown({
        projectId: parseInt(projectId),
        teamId: values.team1.name as string,
      });
  }, [getTradeDropdown, projectId, values.team1.name]);

  useEffect(() => {
    if (
      values.team1.name &&
      values.team2.name &&
      (values.team1.tradingOut.length > 0 || values.team2.tradingOut.length > 0)
    ) {
      getTransactinImpact({
        data: reverseCompletedTradeTransformData(values, team1Dropdown, team2Dropdown),
        projectId: projectId,
      });
    }
  }, [values.team1, values.team2, values.note, team1Dropdown, team2Dropdown]);

  useEffect(() => {
    transactionData && setTransactionImpact(transformTransactionImpact(transactionData));
  }, [transactionData]);

  useEffect(() => {
    values.team2.name &&
      getTeam2TradeDropdown({
        projectId: parseInt(projectId),
        teamId: values.team2.name as string,
      });
  }, [getTeam2TradeDropdown, projectId, values.team2.name]);

  useEffect(() => {
    setTeam1Dropdown(transformPickListDropdown(team1Data));
  }, [setTeam1Dropdown, team1Data]);

  useEffect(() => {
    setTeam2Dropdown(transformPickListDropdown(team2Data));
  }, [setTeam2Dropdown, team2Data]);

  return {
    transactionImpact,
  };
};

export const usePriorityPick = () => {
  const currentProject = useAppSelector(useCurrentProject);
  const { data: showTeamData } = useGetShowTeamQuery('');
  const { data: playerData } = useGetPlayerQuery(currentProject);
  const { data: allPicksData } = useGetAllPickQuery(parseInt(currentProject));
  const { data: faPickDataOption } = useGetPriorityPickTypeQuery('');

  const faPickDropdown = useMemo(
    () => transformFaPickDataOption(faPickDataOption),
    [faPickDataOption],
  );
  return {
    teamDataDropdown: transformAcademyTeamNameDropdownOption(showTeamData?.data),
    playerDataDropdown: transformAcademyPlayerDropdownOption(playerData),
    allPickDataDropdown: transformAcademyAllPickDataOption(allPicksData),
    faPickDropdown,
    currentProject: parseInt(currentProject),
  };
};

export const useFreeAgentCompensationPick = () => {
  const currentProject = useAppSelector(useCurrentProject);
  const { data: showTeamData } = useGetShowTeamQuery('');
  const { data: playerData } = useGetPlayerQuery(currentProject);
  const { data: allPicksData } = useGetAllPickQuery(parseInt(currentProject));
  const { data: faPickDataOption } = useGetFAPickTypeQuery('');

  const faPickDropdown = useMemo(
    () => transformFaPickDataOption(faPickDataOption),
    [faPickDataOption],
  );
  return {
    teamDataDropdown: transformAcademyTeamNameDropdownOption(showTeamData?.data),
    playerDataDropdown: transformAcademyPlayerDropdownOption(playerData),
    allPickDataDropdown: transformAcademyAllPickDataOption(allPicksData),
    faPickDropdown,
    currentProject: parseInt(currentProject),
  };
};

export const useNgaBidMatch = () => {
  const currentProject = useAppSelector(useCurrentProject);
  const { data: showTeamData } = useGetShowTeamQuery('');
  const { data: playerData } = useGetPlayerQuery(currentProject);
  const { data: allPicksData } = useGetAllPickQuery(parseInt(currentProject));
  const [createNgaBid, { isLoading }] = useCreateNgaBidMutation();
  const dispatch = useAppDispatch();
  const [getNgaBid, { data: sumNgaBid }] = useGetNgaTransactionSumMutation();
  const { userInfo } = useAppSelector(useGetUserDetail);
  const fetchNgaBidLine = (values: NgaBidMatchType) => {
    if (values.ngaPlayer && values.pickOfBid && values.teamNgaPlayer) {
      getNgaBid({
        projectId: currentProject,
        data: values,
      });
    }
  };
  const submitData = (values: NgaBidMatchType) => {
    createNgaBid({
      data: values,
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'NGA Bid',
              payload: reverseNgaBidTransformData(values),
            }),
          );
      }
    });
  };
  return {
    teamDataDropdown: transformAcademyTeamNameDropdownOption(showTeamData?.data),
    playerDataDropdown: transformAcademyPlayerDropdownOption(playerData),
    allPickDataDropdown: transformAcademyAllPickDataOption(allPicksData),
    currentProject: parseInt(currentProject),
    submitData,
    isLoading,
    fetchNgaBidLine,
    sumNgaBidLines: transformNgaBidLine(sumNgaBid),
  };
};

export const useManualPickInsert = () => {
  const currentProject = useAppSelector(useCurrentProject);
  const { data: showTeamData } = useGetShowTeamQuery('');
  const { data: roundData } = useGetRoundsQuery('');
  const { data: allPicksData } = useGetAllPickQuery(parseInt(currentProject));
  const dispatch = useAppDispatch();
  const [updateData, { isLoading }] = useManualPickEditApiMutation();
  const [updateManualMove, { isLoading: manualMoveLoading }] = useCreateManualMoveMutation();
  const [updateManualInsert, { isLoading: manualInsertLoading }] =
    useCreateManualInsertMoveMutation();

  const allPickDataDropdown = transformAcademyAllPickDataOption(allPicksData);
  const { userInfo } = useAppSelector(useGetUserDetail);
  const submitData = (value: ManualPickEditType) => {
    updateData({
      data: {
        ...value,
        uniquePick:
          allPickDataDropdown.find((item) => item.label === value.selectPickEditOwner)!.unique ||
          '',
      },
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Manual Pick Edit',
              payload: reverseManualTransformData({
                ...value,
                uniquePick:
                  allPickDataDropdown.find((item) => item.label === value.selectPickEditOwner)!
                    .unique || '',
              }),
            }),
          );
      }
    });
  };
  const roundDropdown = transformRoundDropdownOption(roundData);

  const submitManualMove = (value: ManualPickMoveType) => {
    updateManualMove({
      data: {
        ...value,
        round: roundDropdown.find((item) => item.value === value.round)?.label || '',
      },
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Manual Pick Edit',
              payload: reverseManualMoveTransformData({
                ...value,
                round: roundDropdown.find((item) => item.value === value.round)?.label || '',
              }),
            }),
          );
      }
    });
  };

  const submitManualInsert = (value: ManualPickInsertType) => {
    updateManualInsert({
      data: {
        ...value,
        uniquePick:
          allPickDataDropdown.find((item) => item.value === value.pickLocation)!.unique || '',
        round: roundDropdown.find((item) => item.value === value.round)?.label || '',
      },
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Manual Pick Edit',
              payload: reverseManualInsertTransformData({
                ...value,
                uniquePick:
                  allPickDataDropdown.find((item) => item.value === value.pickLocation)!.unique ||
                  '',
                round: roundDropdown.find((item) => item.value === value.round)?.label || '',
              }),
            }),
          );
      }
    });
  };

  return {
    teamDataDropdown: transformAcademyTeamNameDropdownOption(showTeamData?.data),
    roundDropdown,
    allPickDataDropdown: transformAcademyAllPickDataOption(allPicksData),
    currentProject: parseInt(currentProject),
    submitData,
    isLoading,
    submitManualMove,
    manualMoveLoading,
    submitManualInsert,
    manualInsertLoading,
  };
};

export const useDraftNightSelection = () => {
  const currentProject = useAppSelector(useCurrentProject);
  const { data: allPicksData } = useGetAllPickQuery(parseInt(currentProject));
  const { data: playerData } = useGetPlayerQuery(currentProject);
  const dispatch = useAppDispatch();

  const [createDraft, { isLoading }] = useCreateDraftNightMutation();
  const { userInfo } = useAppSelector(useGetUserDetail);
  const submitData = (values: DraftNightSelectionType) => {
    createDraft({
      data: values,
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Draft Night Selection',
              payload: reverseDraftNightTransformData(values),
            }),
          );
      }
    });
  };
  return {
    allPickDataDropdown: transformAcademyAllPickDataOption(allPicksData),
    currentProject: parseInt(currentProject),
    playerDataDropdown: transformAcademyPlayerDropdownOption(playerData),
    isLoading,
    submitData,
  };
};

export const useDeleteUnusablePick = () => {
  const [deletePick, { isFetching }] = useLazyDeleteUnusablePicksQuery();
  const currentProject = useAppSelector(useCurrentProject);
  const dispatch = useAppDispatch();
  const [fetchRoster, { data, isLoading }] = useLazyFetchRosterSpotsQuery();
  const [rosterList, setRosterList] = useState<YearData[]>([]);

  const [updateRoster, { isLoading: updateRosterLoading }] = useUpdateRosterSpotMutation();

  useEffect(() => {
    fetchRoster({
      projectId: currentProject,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    data && setRosterList(transformRosterData(data).rosterList);
  }, [data]);

  const initialRosterList = useMemo(() => transformRosterData(data).rosterList, [data]);

  const setRosterAmount = (rosterId: number, rosterYear: string, amount: number) => {
    setRosterList((prev) =>
      prev.map((item) =>
        item.year.toString() === rosterYear.toString()
          ? {
              ...item,
              teamInfo: item.teamInfo.map((roster) =>
                roster.teamId.toString() === rosterId.toString()
                  ? {
                      ...roster,
                      rosterSpotsAvailable: amount,
                    }
                  : { ...roster },
              ),
            }
          : { ...item },
      ),
    );
  };

  const submit = () => {
    const updateRosterListWithParticularTeam: YearData[] = [];
    initialRosterList.forEach((item) => {
      const updatedInitialItem = rosterList.find((rost) => rost.year === item.year) as YearData;
      const teamList: TeamInfo[] = [];
      updatedInitialItem.teamInfo.forEach((team) => {
        if (
          !isEqual(
            team,
            item.teamInfo.find((rost) => rost.teamId === team.teamId),
          )
        ) {
          teamList.push(team);
        }
      });
      if (teamList.length > 0) {
        updateRosterListWithParticularTeam.push({
          teamInfo: teamList,
          year: updatedInitialItem.year,
        });
      }
    });

    if (updateRosterListWithParticularTeam.length > 0) {
      updateRoster({
        data: updateRosterListWithParticularTeam,
        projectId: currentProject,
      }).then((res: any) => {
        if (!res.error) {
          toast.success('Roster Updated Successfully');
          editDraftMode();
        }
      });
      return;
    }
    editDraftMode();
  };
  const { userInfo } = useAppSelector(useGetUserDetail);
  const editDraftMode = () => {
    deletePick(currentProject).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Enter Draft Mode',
              payload: {},
            }),
          );
      }
    });
  };

  return {
    submit,
    isLoading: isFetching || updateRosterLoading,
    fetchRosterLoading: isLoading,
    setRosterAmount,
    rosterList,
  };
};
