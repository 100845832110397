/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import {
  useBulkRoasterAllocationMutation,
  useLazyRoasterAllocationYearTeamInfoQuery,
  useValidateBulkPlayerMutation,
} from '../../store/service/clubsummaryservice';
import { useAppSelector } from '../../store/hooks';
import { useCurrentOrganisation } from '../../store/header/selector';
import {
  transformBulkValidationResult,
  transformSmartListPlayer,
  trasnformPlayerListOptions,
  validationBulAddSchema,
} from './util';
import {
  useCreateTimelineEntryMutation,
  useGetCategoryContractOptionQuery,
  useGetTimelineEntryFieldQuery,
  useLazyGetPlayerDatabaseQuery,
  useLazyGetPlayerTimelineQuery,
} from '../../store/service/playerdatabaseservice';
import { PlayerDatabaseType } from '../playerdatabase/type';
import { transformCategoryOption, transformPlayerList } from '../playerdatabase/util';
import { useGetUserTeam } from '../../store/auth/selector';
import { useGetShowTeamQuery } from '../../store/service';
import { OptionType } from '../../common/fields/type';
import { transformTeamNameDropdownOption } from '../scenarioplanning/util';
import { FieldsType } from '../playerinfo/type';
import { transformFieldDetails, transformTimelineData } from '../playerinfo/util';
import { useForm, useWatch } from 'react-hook-form';
import { format } from 'date-fns';
import { BulkContractFormType, CreateContractType, EditPlayerInfoType } from './type';
import { yupResolver } from '@hookform/resolvers/yup';

export const useSmartList = () => {
  const { data: showTeamData } = useGetShowTeamQuery('');
  const [fetchSummaryList, { data, isLoading, error }] =
    useLazyRoasterAllocationYearTeamInfoQuery();
  const [selectedTeam, setSelectedTeam] = useState('');
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [fetchPlayerList, { data: playerList }] = useLazyGetPlayerDatabaseQuery();
  const [selectedPlayerForEdit, setSelectedPlayerForEdit] = useState<
    PlayerDatabaseType | undefined
  >(undefined);
  const [selectedPlayerForAddNote, setSelectedPlayerForAddNote] = useState<
    PlayerDatabaseType | undefined
  >(undefined);
  const [addContract, setHandleAddContract] = useState<CreateContractType | undefined>(undefined);
  const userTeamId = useAppSelector(useGetUserTeam);
  const [teamOptions, setTeamOptions] = useState<OptionType[]>([]);
  const smartListData = useMemo(
    () => transformSmartListPlayer(error ? undefined : data),
    [data, error],
  );
  const [openBulkContractModal, setOpenBulkContractModal] = useState(false);

  useEffect(() => {
    if (showTeamData) {
      const teamData = transformTeamNameDropdownOption(showTeamData?.data);
      setTeamOptions(teamData);
      userTeamId && setSelectedTeam(userTeamId.toString());
    }
  }, [showTeamData]);

  useEffect(() => {
    selectedTeam && handleFetchSummaryList();
    if (currentOrgId !== '') {
      fetchPlayerList(currentOrgId);
      return;
    }
  }, [selectedTeam]);

  const handleFetchSummaryList = () => {
    fetchSummaryList({
      orgId: currentOrgId,
      teamId: selectedTeam,
    });
  };

  const playerListOption = useMemo(() => transformPlayerList(playerList), [playerList]);
  return {
    selectedTeam,
    setSelectedTeam,
    isLoading,
    smartListData,
    selectedPlayerForEdit,
    setSelectedPlayerForEdit,
    refreshData: handleFetchSummaryList,
    playerListInfo: playerListOption,
    playerListOption: trasnformPlayerListOptions(playerListOption),
    teamOptions,
    selectedPlayerForAddNote,
    setSelectedPlayerForAddNote,
    setHandleAddContract,
    addContract,
    setOpenBulkContractModal,
    openBulkContractModal,
  };
};

export const useAddTimelineNote = (playerId: string) => {
  const { data } = useGetTimelineEntryFieldQuery('');
  const [createTimeline, { isLoading: createTimeLineLoading }] = useCreateTimelineEntryMutation();
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [selectedFormFieldInfo, setSelectedFormField] = useState<FieldsType | undefined>();
  const [fetchPlayerTimeline, { data: timelineEntry }] = useLazyGetPlayerTimelineQuery();
  const transformFieldInfo: FieldsType[] = useMemo(() => transformFieldDetails(data), [data]);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    loadForm();
  }, [transformFieldInfo]);

  const loadForm = () => {
    const loadField = transformFieldInfo.find((item) => item.name === 'LIST_MANAGEMENT_NOTE');
    setSelectedFormField(loadField);
    let fieldDetails: Record<string, string | number | null> = {};

    loadField?.fields.forEach((field) => {
      fieldDetails[field.fieldId] = field.value;
    });
    reset(fieldDetails);
  };

  const fetchData = () => {
    playerId &&
      fetchPlayerTimeline({
        orgId: currentOrgId,
        playerId,
      });
  };

  const onSubmit = (value: any) => {
    const payload = Object.entries(value).map((item: any) => ({
      id: item[0],
      value: typeof item[1] === 'object' ? format(new Date(item[1]), 'yyyy-MM-dd') : item[1],
    }));
    playerId &&
      createTimeline({
        data: payload,
        formName: 'LIST_MANAGEMENT_NOTE',
        orgId: currentOrgId,
        playerId: playerId,
      }).then((res: any) => {
        if (!res.error) {
          fetchData();
          reset((formValues) => {
            const emptyValues = Object.keys(formValues).reduce((acc, key) => {
              (acc as any)[key] = '';
              return acc;
            }, {});
            return emptyValues;
          });

          return;
        }
      });
  };

  const transformTimelineListData = useMemo(
    () => transformTimelineData(timelineEntry),
    [timelineEntry],
  );

  return {
    data: transformFieldInfo,
    isLoading: createTimeLineLoading,
    loadForm,
    selectedFormFieldInfo,
    register,
    errors,
    handleSubmit,
    onSubmit,
    control,
    timelineEntry: transformTimelineListData,
  };
};

export const useBulkContractModal = ({
  selectedTeamName,
  playerListInfo,
  handleRefreshList,
}: {
  selectedTeamName?: string;
  playerListInfo: PlayerDatabaseType[];
  handleRefreshList: () => void;
}) => {
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [refreshList, setRefreshList] = useState(Math.random());
  const { control, register, setValue, handleSubmit } = useForm({
    defaultValues: {
      selectedTeam: selectedTeamName || '',
      startSeason: new Date().toString(),
      endSeason: new Date().toString(),
      contractType: '',
      selectedPlayerList: [] as number[],
      playerInfo: [] as EditPlayerInfoType[],
    },
    resolver: yupResolver(validationBulAddSchema),
  });
  const [createBulkRoaster, { isLoading }] = useBulkRoasterAllocationMutation();
  const [playerSearchText, setPlayerSearchText] = useState('');
  const { data: categoryOpt } = useGetCategoryContractOptionQuery('');

  const catOpt = useMemo(() => {
    const data = transformCategoryOption(categoryOpt);
    setValue('contractType', data?.[0]?.value || '');
    return data;
  }, [categoryOpt]);

  const [validateBulkPlayer, { isLoading: validateBulkLoading, data }] =
    useValidateBulkPlayerMutation();

  const bulkValidationResult = useMemo(() => transformBulkValidationResult(data), [data]);
  const selectedTeam = useWatch({
    control,
    name: 'selectedTeam',
  });

  const startSeason = useWatch({
    control,
    name: 'startSeason',
  });

  const endSeason = useWatch({
    control,
    name: 'endSeason',
  });

  const selectedPlayerList = useWatch({
    control,
    name: 'selectedPlayerList',
  });

  const contractType = useWatch({
    control,
    name: 'contractType',
  });

  const playerInfo = useWatch({
    control,
    name: 'playerInfo',
  });

  useEffect(() => {
    if (selectedPlayerList.length > 0) {
      setValue(
        'playerInfo',
        selectedPlayerList.map((playerId) => {
          const findPlayer = playerInfo.find((item) => item.playerId === playerId);
          if (findPlayer) {
            return findPlayer;
          }
          const { firstName, lastName } = playerListInfo.find(
            (item) => item.id.toString() === playerId.toString(),
          ) as {
            firstName: string;
            lastName: string;
          };
          return {
            playerName: `${firstName || ''} ${lastName || ''}`,
            playerId,
            startSeason: startSeason,
            endSeason: endSeason,
            contractType: contractType,
          };
        }),
      );
      return;
    }
    setValue('playerInfo', []);
  }, [selectedPlayerList]);

  const handlePlayerRemove = (playerId: number) => {
    setValue(
      'selectedPlayerList',
      selectedPlayerList.filter((item) => item !== playerId),
    );
  };

  const onSubmit = (data: BulkContractFormType) => {
    createBulkRoaster({
      data,
      orgId: currentOrgId,
      teamId: selectedTeam,
    }).then((res: any) => {
      if (!res.error) {
        handleRefreshList();
      }
    });
  };

  const handleValidateBulkPlayer = () => {
    validateBulkPlayer({
      data: playerInfo,
      orgId: currentOrgId,
      teamId: selectedTeam,
    }).then((res: any) => {
      if (!res.error) {
        return;
      }
    });
  };

  const handleUpdateAllPlayer = () => {
    setValue(
      'playerInfo',
      playerInfo.map((item) => ({
        playerName: item.playerName,
        playerId: item.playerId,
        startSeason: startSeason,
        endSeason: endSeason,
        contractType: contractType,
      })),
    );
    setTimeout(() => {
      setRefreshList(Math.random());
    }, 100);
  };
  return {
    control,
    register,
    selectedTeam,
    catOpt,
    contractType,
    playerSearchText,
    setPlayerSearchText,
    selectedPlayerList,
    playerInfo,
    handlePlayerRemove,
    handleSubmit,
    onSubmit,
    isLoading,
    orgId: currentOrgId,
    handleValidateBulkPlayer,
    validateBulkLoading,
    bulkValidationResult,
    handleUpdateAllPlayer,
    refreshList,
  };
};
