import { flatten } from 'lodash';
import { PicksOptionType, PicksTradingType } from '../../type';
import {
  MultiPlayerType,
  MultiTeamType,
  MultiTradeImpactType,
  MultiTradeType,
  PicksDropdownType,
  TeamPicks,
} from './type';
import { TransactionMultiTeamTradeDetailsType } from '../../../scenarioplanning/type';

export const MultiTradeInitialValue = (
  transactionDetails?: TransactionMultiTeamTradeDetailsType[],
): MultiTradeType => {
  if (transactionDetails) {
    return {
      team: transactionDetails?.map((item) => ({
        id: Math.random().toString(),
        picksIn: item.picksIn.map((item) => item.value),
        playersIn: item.playersIn.map((player) => ({
          clubId: player.teamId,
          id: Math.random().toString(),
          playerName: player.playerId,
        })),
        teamId: item.teamId,
      })),
    };
  }
  return {
    team: [
      {
        id: Math.random().toString(),
        picksIn: [],
        playersIn: [
          {
            clubId: '',
            id: Math.random().toString(),
            playerName: '',
          },
        ],
        teamId: '',
      },
      {
        id: Math.random().toString(),
        picksIn: [],
        playersIn: [
          {
            clubId: '',
            id: Math.random().toString(),
            playerName: '',
          },
        ],
        teamId: '',
      },
    ],
  };
};

const reverseTransformPlayersIn = (data: MultiPlayerType[]) => {
  if (data.length === 0) {
    return [];
  }
  if ((data.length === 1 && data[0].playerName === '') || data[0].clubId === '') {
    return [];
  }
  return data.map((player) => ({
    player_id: player.playerName,
    team_id: player.clubId,
  }));
};
export const reverseMultiImpactTransformData = (
  data: MultiTeamType[],
  pickOptions: PicksTradingType[],
) => {
  const pickOpt: any = [];
  pickOptions.forEach((item) => {
    pickOpt.push(...flatten(item.picksOption));
  });
  return data.map((item) => ({
    team_id: item.teamId,
    players_in: reverseTransformPlayersIn(item.playersIn),
    picks_in: item.picksIn.map((picks) =>
      pickOpt?.find((opt: any) => Number(opt.value) === Number(picks)),
    ),
  }));
};

export const transformMultipleAcademyAllPickDataOption = (data?: any): PicksDropdownType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (item: any) =>
      ({
        value: item.value,
        label: item.label,
        unique: item.unique,
        pick_status: item.pick_status,
        overall_pick: item.overall_pick,
        current_owner: item.current_owner,
        display: item.label,
      } as PicksDropdownType),
  );
};

export const transformPick = (data: any) => {
  if (!data) {
    return [];
  }
  return data.map((item: any) => ({
    currentOwner: item.current_owner,
    display: item.display,
    label: item.label,
    unique: item.unique,
    value: item.value,
    overallPick: item?.overall_pick || item.value,
  }));
};
export const transformMultiTradeImpact = (data: any): MultiTradeImpactType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        netResult: item.net_result,
        picksIn: transformPick(item.picks_in),
        picksOut: transformPick(item.picks_out),
        playersIn: item.players_in.map(
          (player: any) =>
            ({
              id: '',
              clubId: player.team_id,
              playerName: player.player_id,
            } as MultiPlayerType),
        ),
        playersOut: item.players_out.map(
          (player: any) =>
            ({
              id: '',
              clubId: player.team_id,
              playerName: player.player_id,
            } as MultiPlayerType),
        ),
        ptsIn: item.pts_in,
        ptsOut: item.pts_out,
        teamId: item.team_id,
      } as MultiTradeImpactType),
  );
};

export const transformTeamPicks = (picks: PicksOptionType[]): TeamPicks[] => {
  // First group picks by teamId (current_owner)
  const teamGroups = picks.reduce((acc, pick) => {
    const teamId = pick.current_owner;
    if (!acc[teamId]) {
      acc[teamId] = [];
    }
    acc[teamId].push(pick);
    return acc;
  }, {} as { [key: number]: PicksOptionType[] });

  // Transform each team's picks
  return Object.entries(teamGroups).map(([teamId, teamPicks]) => {
    // Get unique years for this team
    const years = Array.from(new Set(teamPicks.map((pick) => pick.year)));

    // Create pickList with year grouping
    const pickList = years.map((year) => ({
      year: Number(year),
      picks: teamPicks.filter((pick) => pick.year === year),
    }));

    return {
      teamId: parseInt(teamId),
      pickList,
    };
  });
};
