import { BulkValidateConflictType, EditPlayerInfoType } from '../type';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PlayerCardInfo from './PlayerCardInfo';
import { Control } from 'react-hook-form';
import { OptionType } from '../../../common/fields/type';

type PlayerListTableProps = {
  playerList: EditPlayerInfoType[];
  control: Control<any, any>;
  contractOptions: OptionType[];
  handleDelete: (playerId: number) => void;
  orgId: string;
  teamId: string;
  bulkValidationResult: BulkValidateConflictType | null;
  refreshList: number;
};

const PlayerListTable = ({
  playerList,
  control,
  contractOptions,
  handleDelete,
  orgId,
  teamId,
  bulkValidationResult,
  refreshList,
}: PlayerListTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: 'grey.100' }}>
            <TableCell>
              <Typography fontWeight={'bold'}>Player Name</Typography>{' '}
            </TableCell>
            <TableCell>
              <Typography fontWeight={'bold'}>Start Season</Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight={'bold'}>End Season</Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight={'bold'}>Contract Type</Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {playerList.map((item, index) => (
            <PlayerCardInfo
              contractOptions={contractOptions}
              playerInfo={item}
              orgId={orgId}
              control={control}
              index={index}
              teamId={teamId}
              key={`${item.playerId}-${index}-${refreshList}`}
              validationError={bulkValidationResult?.validationResults.find(
                (player) => player.playerId === item.playerId,
              )}
              handleDelete={handleDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlayerListTable;
