import { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';
import { StyledButton, StyledOutlineButton, StyledTextButton } from './Style';

type Props = {
  isLoading?: boolean;
  title: string;
} & ButtonProps;

export const PrimaryButton: FC<Props> = ({ isLoading, title, ...rest }) => {
  return (
    <StyledButton disabled={isLoading} {...rest}>
      {isLoading ? <CircularProgress size={'24px'} /> : title}
    </StyledButton>
  );
};

export const PrimaryOutlineButton: FC<Props> = ({ isLoading, title, ...rest }) => {
  return (
    <StyledOutlineButton disabled={isLoading} {...rest}>
      {isLoading ? <CircularProgress size={'24px'} /> : title}
    </StyledOutlineButton>
  );
};

export const PrimaryTextButton: FC<Props> = ({ isLoading, title, ...rest }) => {
  return (
    <StyledTextButton disabled={isLoading} {...rest}>
      {isLoading ? <CircularProgress size={'20px'} /> : title}
    </StyledTextButton>
  );
};
