import { Grid } from '@mui/material';
import CustomModal from '../../../common/component/CustomModal';
import { useBulkContractModal } from '../hook';
import { OptionType } from '../../../common/fields/type';
import CustomSelect from '../../../common/fields/reacthookfield/CustomSelect';
import CustomeDatepicker from '../../../common/fields/reacthookfield/CustomeDatepicker';
import { add, sub } from 'date-fns';
import { PlayerDatabaseType } from '../../playerdatabase/type';
import CustomAutocomplete from '../../../common/fields/reacthookfield/CustomAutocomplete';
import PlayerListTable from './PlayerListTable';
import { PrimaryButton } from '../../../common/component/Button';

type BulkContractModalProps = {
  handleClose: () => void;
  selectedTeamName?: string;
  teamOptions: OptionType[];
  playerListInfo: PlayerDatabaseType[];
  playerListOption: OptionType[];
  handleRefreshList: () => void;
};
const BulkContractModal = ({
  handleClose,
  selectedTeamName,
  teamOptions,
  playerListInfo,
  playerListOption,
  handleRefreshList,
}: BulkContractModalProps) => {
  const {
    control,
    register,
    playerInfo,
    selectedTeam,
    catOpt,
    contractType,
    handlePlayerRemove,
    handleSubmit,
    isLoading,
    onSubmit,
    orgId,
    handleValidateBulkPlayer,
    validateBulkLoading,
    bulkValidationResult,
    handleUpdateAllPlayer,
    refreshList,
  } = useBulkContractModal({
    selectedTeamName,
    playerListInfo,
    handleRefreshList,
  });
  return (
    <CustomModal
      handleSubmit={handleSubmit(onSubmit)}
      saveLoading={isLoading}
      modalSize='lg'
      handleClose={handleClose}
      modalTitle='Add Bulk Contracts'
      extraSecondaryButton={[
        {
          label: 'Validate All',
          onClick: handleValidateBulkPlayer,
          isLoading: validateBulkLoading,
        },
      ]}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems={'center'} columnSpacing={'30px'}>
            <Grid item xs={3}>
              <CustomSelect
                label='Team'
                options={teamOptions}
                fullWidth
                id='team'
                value={selectedTeam}
                register={register}
                variant='outlined'
                control={control}
                name='team'
              />
            </Grid>
            <Grid item xs={2}>
              <CustomeDatepicker
                isMandatory
                control={control}
                fieldLabel={'Start Season'}
                name={'startSeason'}
                views={['year']}
                format='yyyy'
                defaultDay={1}
                defaultMonth={1}
                minDate={sub(new Date(), {
                  years: 10,
                })}
                maxDate={add(new Date(), {
                  years: 10,
                })}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomeDatepicker
                isMandatory
                control={control}
                fieldLabel={'End Season'}
                name={'endSeason'}
                views={['year']}
                defaultDay={1}
                format='yyyy'
                defaultMonth={1}
                minDate={sub(new Date(), {
                  years: 10,
                })}
                maxDate={add(new Date(), {
                  years: 10,
                })}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomSelect
                options={catOpt}
                id='contractType'
                name={'contractType'}
                register={register}
                value={contractType}
                fullWidth
                key={contractType}
                variant='outlined'
                label={'Contract Type'}
              />
            </Grid>
            <Grid item xs={2} pt={'15px'}>
              <PrimaryButton title='Apply To All' onClick={handleUpdateAllPlayer} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            '& .MuiChip-root': {
              display: 'none',
            },
          }}
          item
          xs={12}
          mt={'20px'}
        >
          <CustomAutocomplete
            name='selectedPlayerList'
            id='selectedPlayerList'
            control={control}
            ismultiselect
            options={playerListOption}
            label={'Player List'}
          />
        </Grid>
        <Grid item xs={12} mt={'20px'}>
          <PlayerListTable
            playerList={playerInfo}
            contractOptions={catOpt}
            orgId={orgId}
            refreshList={refreshList}
            control={control}
            teamId={selectedTeam}
            bulkValidationResult={bulkValidationResult}
            handleDelete={handlePlayerRemove}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default BulkContractModal;
