import { OptionType } from '../../common/fields/type';
import { AddPlayerType } from '../../container/draftboard/type';
import { YearData } from '../../container/project/type';
import { RegisterType } from '../../container/register/type';
import { TradingType } from '../../container/tradeanalysis/type';
import {
  AcademyBidMatchType,
  CompletedTradeType,
  DraftNightSelectionType,
  FatherBidMatchType,
  ManualPickMoveType,
  NgaBidMatchType,
} from '../../container/transaction/type';
import {
  IReverseCreateProject,
  IReverseFreeAgentCompensation,
  IReverseManualPickEdit,
  IReverseManualPickInsert,
  IReversePriorityPick,
} from './type';

export const reverseManualTransformData = (data: IReverseManualPickEdit) => {
  return {
    pick: `${data.selectPickEditOwner}`,
    reason: `${data.selectReasonPickEdit}`,
    team_new: `${data.newOwnerPick}`,
    team_via: `${data.mostRecentPick}`,
    unique_pick: `${data.uniquePick}`,
  };
};

export const reverseDraftNightTransformData = (data: DraftNightSelectionType) => {
  return {
    player_taken_id: `${data.playerTaken}`,
    selected_pick_id: `${data.teamSelectPick}`,
  };
};

export const reverseManualMoveTransformData = (data: ManualPickMoveType) => {
  return {
    instructions: `${data.insertInstruction}`,
    pick_being_moved: `${data.pickMove}`,
    pick_moved_to: `${data.pickDestination}`,
    reason: `${data.enterReason}`,
    round: `${data.round}`,
    teamid: `${data.team}`,
  };
};

export const reverseManualInsertTransformData = (data: IReverseManualPickInsert) => {
  return {
    manual_insert_instructions: `${data.insertInstruction}`,
    manual_aligned_pick: `${data.pickLocation}`,
    reason: `${data.enterReason}`,
    manual_round: `${data.round}`,
    manual_team: `${data.team}`,
    unique_pick: `${data.uniquePick}`,
  };
};

export const reverseNgaBidTransformData = (data: NgaBidMatchType) => {
  return {
    pickid: `${data.pickOfBid}`,
    playerid: `${data.ngaPlayer}`,
    teamid: `${data.teamNgaPlayer}`,
  };
};

export const reverseFatherBidTransformData = (data: FatherBidMatchType) => {
  return {
    pickid: `${data.pickOfBid}`,
    fs_team: `${data.fatherSonBelongTo}`,
    playerid: `${data.fatherSonPlayers}`,
  };
};

export const reverseAcademyBidTransformData = (data: AcademyBidMatchType) => {
  return {
    pickid: `${data.bidPick}`,
    academy_team: `${data.playerBelongsTo}`,
    playerid: `${data.academyPlayer}`,
  };
};

export const reverseFreeAgentTransformData = (data: IReverseFreeAgentCompensation) => {
  return {
    instructions: `${data.pickInstruction}`,
    pick_id: `${data.compensationAligned}`,
    pick_type: `${data.compensationPickType}`,
    reason: `${data.reason}`,
    teamid: `${data.teamReceivingCompensation}`,
    unique_pick: data.uniquePick,
  };
};

export const reversePriorityPickTransformData = (data: IReversePriorityPick, projectId: string) => {
  return {
    pp_insert_instructions: `${data.pickInstruction}`,
    ppid: `${data.pickAligned}`,
    pick_type: `${data.typePriorityPick}`,
    reason: `${data.reason}`,
    teamid: `${data.teamPriorityPick}`,
    projectId: projectId,
    unique_pick: data.uniquePick,
  };
};

export const reverseCompletedTradeTransformData = (
  data: CompletedTradeType,
  team1Dropdown: OptionType[],
  team2Dropdown: OptionType[],
): any => {
  return {
    teamid1: data.team1.name,
    teamid2: data.team2.name,
    player1: data.team1.playerName
      ? data.team1.playerName.split(',').map((item) => ({
          player_id: item,
        }))
      : [],
    player2: data.team2.playerName
      ? data.team2.playerName.split(',').map((item) => ({
          player_id: item,
        }))
      : [],
    pickid1: team1Dropdown.filter((item) =>
      (data.team1.tradingOut as number[]).includes(parseInt(item.value)),
    ),
    pickid2: team2Dropdown.filter((item) =>
      (data.team2.tradingOut as number[]).includes(parseInt(item.value)),
    ),
    notes: data?.note || undefined,
  };
};

export const reverseTradeOfferTransformData = (
  data: CompletedTradeType,
  team1Dropdown: OptionType[],
  team2Dropdown: OptionType[],
) => {
  return {
    ...reverseCompletedTradeTransformData(data, team1Dropdown, team2Dropdown),
    offer_status: data.offerStatus || 'n/a',
    offer_type: data.offerType || 'n/a',
    last_modified: data.lastModified ? new Date(data.lastModified) : undefined,
    created: data.created ? new Date(data.created) : undefined,
  };
};
export const reverseCreateProjectTransformData = (data: IReverseCreateProject) => {
  return {
    project_name: data.projectName,
    project_desc: data.projectDescription,
    teamid: data.team,
    year: data.draftYear,
    draft_type: data.draftType,
    bid_discounts: data.bidDiscounts,
    bidding_discount_type: 'RULE_1',
    draft_value_index: data.draftValueIndex,
    draft_value_index_type: data.draftYear === 2025 ? 'RULE_2' : 'RULE_1',
  };
};

export const reverseTransformTradeInfo = (data: TradingType, userId: number) => {
  return {
    number_possible_solutions: data.tradingOut.noOfSolution,
    c1_type: data.tradingOut.picksIncludeInTradeType,
    c1_set: data.tradingOut.picksIncludeInTrade,
    c2_type: data.tradingOut.seasonIncludeInTradeType,
    c2_set: data.tradingOut.seasonIncludeInTrade,
    c3_type: data.tradingOut.roundsIncludeInTradeType,
    c3_set: data.tradingOut.roundsIncludeInTrade,
    c4_type: data.tradingOut.minimumDraftPointsType,
    c4_set: data.tradingOut.minimumDraftPoints,
    c5_type: data.tradingOut.maximumDraftPointsType,
    c5_set: data.tradingOut.maximumDraftPoints,
    c6_type: data.tradingin.teamsToTradeWithType,
    c6_set: data.tradingin.teamsToTradeWith,
    c7_type: data.tradingin.picksToReceiveInTradeType,
    c7_set: data.tradingin.picksToReceiveInTrade,
    c8_type: data.tradingin.seasonToReceiveTradeType,
    c8_set: data.tradingin.seasonToReceiveTrade,
    c9_type: data.tradingin.roundsToReceiveTradeType,
    c9_set: data.tradingin.roundsToReceiveTrade,
    c10_type: data.tradingin.minDraftPointsType,
    c10_set: data.tradingin.minDraftPoints,
    c11_set: data.tradingin.maxDraftPoints,
    c11_type: data.tradingin.maxDraftPointsType,
    c12_set: data.tradingin.maximumDiffBetweenPerc,
    c12_type: data.tradingin.maximumDiffBetweenPercType,
    c13_type: data.tradingin.minNoOfPicksToRecieveType,
    c13_set: data.tradingin.minNoOfPicksToRecieve,
    c14_type: data.tradingin.maxNoOfPicksToRecieveType,
    c14_set: data.tradingin.maxNoOfPicksToRecieve,
    userid: userId,
  };
};

export const reverseCreateNewPlayerTransformData = (data: AddPlayerType, projectId: string) => {
  return [
    {
      FirstName: data.firstName,
      LastName: data.lastName,
      Position_1: data.position1,
    },
  ];
};

export const reverseCreateNewPlayerCsvTransformData = (data: AddPlayerType, projectId: string) => {
  const formData = new FormData();
  if (data.file && typeof data.file !== 'string') {
    formData.append('file', data.file);
  }

  formData.append('projectId', projectId);
  return formData;
};

export const reverseRegsiterUserTransformData = (data: RegisterType) => {
  return {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    is_staff: data.permission === 'is_staff',
    is_superuser: data.permission === 'is_super',
    team_id: Number(data.teamId),
  };
};

export const ReverseNgaBidMatchType = (data: NgaBidMatchType) => ({
  pickid: data.pickOfBid,
  playerid: data.ngaPlayer,
  teamid: data.teamNgaPlayer,
});

export const reverseTransformUpdateRoster = (data: YearData[]) => {
  const flatArray: {
    team_id: number;
    year: number;
    roster_spots_available: number;
  }[] = [];
  data.forEach((item) => {
    item.teamInfo.forEach((team) => {
      flatArray.push({
        team_id: team.teamId,
        year: item.year,
        roster_spots_available: team.rosterSpotsAvailable,
      });
    });
  });
  return flatArray;
};
