import { FC } from 'react';
import { OptionType } from '../type';
import { SelectProps, TextFieldProps, Select, MenuItem, FormHelperText, Box } from '@mui/material';
import { Control, Controller, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { StyledSelectField } from '../Style';
import { FieldLabel } from '../../component/Text';
import { TEXT_COLOR_THEME } from '../../../theme/color';

type CustomSelectProps = {
  id: string;
  name: string;
  options: OptionType[];
  label?: string;
  errors?: FieldErrors<FieldValues>;
  register?: UseFormRegister<any>;
  isMandatory?: boolean;
  control?: Control<any>;
} & SelectProps &
  TextFieldProps;

const CustomSelect: FC<CustomSelectProps> = ({
  id,
  name,
  options,
  label,
  errors,
  register,
  isMandatory,
  control,
  ...rest
}) => {
  if (control) {
    return (
      <StyledSelectField iserror={errors?.[name]?.message ? 1 : 0} fullWidth size='small'>
        {label && (
          <FieldLabel>
            {label}
            {isMandatory && <sup style={{ color: TEXT_COLOR_THEME.error }}>*</sup>}
          </FieldLabel>
        )}
        <Controller
          control={control}
          name={`${name}`}
          render={({ field, fieldState }) => (
            <Box>
              <Select
                label={''}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                {...rest}
              >
                {options.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error && (
                <FormHelperText style={{ color: TEXT_COLOR_THEME.error }}>
                  {(fieldState.error?.message as string) || ''}
                </FormHelperText>
              )}
            </Box>
          )}
        />
      </StyledSelectField>
    );
  }
  if (register) {
    return (
      <StyledSelectField iserror={errors?.[name]?.message ? 1 : 0} fullWidth size='small'>
        {label && (
          <FieldLabel>
            {label}
            {isMandatory && <sup style={{ color: TEXT_COLOR_THEME.error }}>*</sup>}
          </FieldLabel>
        )}
        <Select label={''} {...register(name)} {...rest}>
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {errors?.[name] && (
          <FormHelperText sx={{ color: TEXT_COLOR_THEME.error }}>
            {(errors[name]?.message as string) || ''}
          </FormHelperText>
        )}
      </StyledSelectField>
    );
  }
  return <></>;
};

export default CustomSelect;
