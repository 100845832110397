import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Tooltip,
  Typography,
  Grid,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ClubSummaryPlayerType, CreateContractType, SummaryDataType } from '../type';
import { getBorderRadiusForContract, getMarginForContract, getSummaryGridBgColor } from '../util';
import { format } from 'date-fns';
import RowHeader from './RowHeader';
import { BG_COLOR } from '../../../theme/color';
import { flatten } from 'lodash';
import SummaryInfoCard from './SummaryInfoCard';
import {
  ScrollIconCell,
  SimpleTableCell,
  StickyHeaderAndLeftCell,
  StickyHeaderCell,
  StickyTableCell,
  StyledTableContainer,
} from '../style';
import SearchPlayerTextfield from './SearchPlayerTextfield';
import { OptionType } from '../../../common/fields/type';
import { PrimaryTextButton } from '../../../common/component/Button';
import { AddCircle } from '@mui/icons-material';

interface SmartPlayerProps {
  years: string[];
  playerList: ClubSummaryPlayerType[];
  summaryList: SummaryDataType[];
  onEditClick: (playerId: number) => void;
  onAddNoteClick: (playerId: number) => void;
  onYearAddContractClick: (contractInfo: CreateContractType) => void;
  playerListOption: OptionType[];
  loading: boolean;
  setOpenBulkContractModal: (value: boolean) => void;
}

const DEFAULT_ROWS = [
  { id: 'primary', title: 'Primary List' },
  { id: 'rookie', title: 'Rookie List' },
  { id: 'total', title: 'Total' },
];

const SmartPlayer: React.FC<SmartPlayerProps> = ({
  years = [],
  playerList = [],
  onAddNoteClick,
  onEditClick,
  onYearAddContractClick,
  summaryList,
  playerListOption,
  loading,
  setOpenBulkContractModal,
}) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const [showSearchPlayerField, setShowSearchPlayerField] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState('');

  useEffect(() => {
    const container = tableRef.current;
    const year = new Date().getFullYear();

    if (container) {
      const ele = document.getElementById(`${year}`);
      if (ele) {
        const left = ele.offsetLeft;

        container.scrollTo({
          behavior: 'smooth',
          left: left,
        });
      }
    }
  }, [loading]);

  const handleScrollLeft = () => {
    const container = tableRef.current;
    if (container && container.scrollLeft >= 0) {
      const scrollLeft = container.scrollLeft - 81;
      container.scrollTo({
        behavior: 'smooth',
        left: scrollLeft,
      });
    }
  };
  const handleScrollRight = () => {
    const container = tableRef.current;
    if (container && container.scrollLeft < container.scrollWidth) {
      const scrollLeft = container.scrollLeft + 81;
      container.scrollTo({
        behavior: 'smooth',
        left: scrollLeft,
      });
    }
  };

  const transformData = useMemo(() => {
    return playerList.map((item) => ({
      id: item.id,
      playerName: item.playerName,
      contractDetailsList: years.map((year) => ({
        year: Number(year),
        contractDetails: item.contractDetails,
        value: item.contractDetails.find((contract) => Number(contract.year) === Number(year))
          ?.contract,
      })),
    }));
  }, [playerList, years]);

  const getData = (data: SummaryDataType, type: string) => {
    if (type === 'primary') {
      return {
        officialValue: data.primaryListOfficial,
        projectedValue: data.primaryListProjected,
      };
    }
    if (type === 'rookie') {
      return {
        officialValue: data.rookieListOfficial,
        projectedValue: data.rookieListProjected,
      };
    }
    if (type === 'total') {
      return {
        officialValue: data.totalListOfficial,
        projectedValue: data.totalListProjected,
      };
    }
  };
  return (
    <Box sx={{ position: 'relative', maxWidth: 'xl', mx: 'auto' }}>
      <Paper elevation={2}>
        <StyledTableContainer ref={tableRef}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StickyHeaderAndLeftCell sx={{ minWidth: 200 }}>
                  <Typography fontWeight={900} fontSize={'12px'}>
                    Player Name
                  </Typography>
                </StickyHeaderAndLeftCell>
                <ScrollIconCell style={{ background: 'white' }} position='left'>
                  <IconButton onClick={() => handleScrollLeft()} size='small' sx={{ padding: 0 }}>
                    <ChevronLeftIcon />
                  </IconButton>
                </ScrollIconCell>
                {years.map((year) => (
                  <StickyHeaderCell
                    id={`${year}`}
                    style={{ width: '81px', padding: 0 }}
                    key={`yearlist-${year}`}
                    align='center'
                  >
                    <Typography width={'81px'} fontWeight={900} fontSize={'12px'}>
                      {year}
                    </Typography>
                  </StickyHeaderCell>
                ))}
                <ScrollIconCell style={{ background: 'white' }} position='right'>
                  <IconButton onClick={() => handleScrollRight()} size='small' sx={{ padding: 0 }}>
                    <ChevronRightIcon />
                  </IconButton>
                </ScrollIconCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {DEFAULT_ROWS.map((row) => (
                <TableRow key={row.id}>
                  <StickyTableCell sx={{ minWidth: 200, padding: 0 }}>
                    <RowHeader title={row.title} />
                  </StickyTableCell>
                  <SimpleTableCell sx={{ width: 48 }} />
                  {years.map((value, index) => {
                    const findData = summaryList.find((data) => data.year === Number(value));

                    if (!findData) {
                      return (
                        <SimpleTableCell
                          style={{
                            border: '1px dashed #e0e0e0',
                            padding: 0,
                          }}
                          key={index}
                          align='center'
                        >
                          <SummaryInfoCard
                            officialValue={0}
                            projectedValue={0}
                            summaryInfoYear={value}
                            year={value}
                          />
                        </SimpleTableCell>
                      );
                    }
                    const data = getData(findData, row.id);
                    return (
                      <SimpleTableCell
                        style={{
                          border: '1px dashed #e0e0e0',
                          padding: 0,
                        }}
                        key={index}
                        align='center'
                      >
                        <SummaryInfoCard
                          officialValue={data?.officialValue || 0}
                          projectedValue={data?.projectedValue || 0}
                          summaryInfoYear={findData?.year.toString() || ''}
                          year={value}
                        />
                      </SimpleTableCell>
                    );
                  })}
                  <SimpleTableCell sx={{ width: 48 }} />
                </TableRow>
              ))}
              {transformData.map((player) => (
                <TableRow
                  key={`player-list${player.id}`}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StickyTableCell sx={{ minWidth: 200, padding: 0 }}>
                    <RowHeader
                      title={player.playerName}
                      key={player.id}
                      onAddNoteClick={() => onAddNoteClick(player.id)}
                      onEditNoteClick={() => onEditClick(player.id)}
                    />
                  </StickyTableCell>
                  <SimpleTableCell sx={{ width: 48 }} />
                  {player.contractDetailsList.map((contract) => {
                    if (!contract.value) {
                      return (
                        <SimpleTableCell
                          style={{ padding: 0, border: 0 }}
                          key={`contract-${contract.year}`}
                          align='center'
                        >
                          <Box
                            height={'30px'}
                            flex={1}
                            onClick={() => {
                              onYearAddContractClick({
                                playerId: player.id.toString(),
                                contracts: flatten(
                                  contract.contractDetails.map((detail) => detail.contract),
                                ),
                                selectedYear: contract.year.toString(),
                              });
                            }}
                            style={{
                              padding: 0,
                              border: '1px dashed #e0e0e0',
                              cursor: 'pointer',
                            }}
                          />
                        </SimpleTableCell>
                      );
                    }
                    return (
                      <SimpleTableCell
                        style={{ padding: 0, border: 0 }}
                        key={contract.year}
                        align='center'
                      >
                        <Box display={'flex'}>
                          {contract.value?.map((data) => {
                            const contractDetail = contract.contractDetails.find(
                              (det) => det.year.toString() === contract.year.toString(),
                            );
                            if (contractDetail) {
                              return (
                                <Tooltip
                                  key={`contractDetail-${data.contractId}`}
                                  title={
                                    <Box>
                                      <Typography>Player name: {player.playerName}</Typography>
                                      <Typography>
                                        Start Season: {format(new Date(data.startDate), 'yyyy')}
                                      </Typography>
                                      <Typography>
                                        End Season: {format(new Date(data.endDate), 'yyyy')}
                                      </Typography>
                                      <Typography>Contract Type: {data.contractType}</Typography>
                                    </Box>
                                  }
                                >
                                  <Box
                                    key={data.contractId}
                                    height={'30px'}
                                    flex={1}
                                    style={{
                                      padding: 0,
                                    }}
                                    mr={getMarginForContract(contractDetail)}
                                    borderRadius={getBorderRadiusForContract(contractDetail)}
                                    bgcolor={getSummaryGridBgColor(data.contractType)}
                                  />
                                </Tooltip>
                              );
                            }
                            return (
                              <Box
                                border={'1px #ccc dashed'}
                                flex={1}
                                mt={'5px'}
                                key={player.id}
                                sx={{ cursor: 'pointer' }}
                                height={'30px'}
                                bgcolor={BG_COLOR.white200}
                              />
                            );
                          })}
                        </Box>
                      </SimpleTableCell>
                    );
                  })}
                  <SimpleTableCell sx={{ width: 48 }} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Grid container>
          <Grid px={'5px'} my={'10px'} item width={'240px'}>
            {showSearchPlayerField ? (
              <SearchPlayerTextfield
                options={playerListOption}
                selectedPlayer={selectedPlayer}
                setSelectedPlayer={(id) => {
                  setSelectedPlayer(id);
                  onEditClick(Number(id));
                }}
                setShowSearchPlayerField={setShowSearchPlayerField}
              />
            ) : (
              <Box>
                <PrimaryTextButton
                  sx={{
                    borderRadius: 0,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => setShowSearchPlayerField(true)}
                  endIcon={<AddCircle />}
                  title='Click To Add player'
                />
                <PrimaryTextButton
                  sx={{
                    borderRadius: 0,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => setOpenBulkContractModal(true)}
                  title='Click to Add Multiple Players'
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default SmartPlayer;
