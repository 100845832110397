import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { FC } from 'react';
import { OptionType } from './type';

type Props = {
  options: OptionType[];
  value: string;
  label?: string;
  onOptionChange: (option: string) => void;
} & FormControlProps;

const CustomDropdown: FC<Props> = ({ options, onOptionChange, label, value, ...rest }) => {
  const handleChange = (event: SelectChangeEvent) => {
    onOptionChange(event.target.value);
  };
  return (
    <FormControl variant='standard' {...rest}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} fullWidth label={label} onChange={handleChange}>
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomDropdown;
