import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { FC } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FieldLabel } from '../../component/Text';
import { Control, Controller } from 'react-hook-form';
import { TEXT_COLOR_THEME } from '../../../theme/color';
import { FormHelperText, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { replaceDay } from '../../../util/SiteHelper';

type CustomeDatepickerProps = {
  name: string;
  fieldLabel?: string;
  control: Control<any, any>;
  isMandatory?: boolean;
  tooltipInfo?: string;
  defaultDay?: number;
  defaultMonth?: number;
} & DatePickerProps<any>;
const CustomeDatepicker: FC<CustomeDatepickerProps> = ({
  name,
  fieldLabel,
  isMandatory,
  control,
  tooltipInfo,
  defaultDay,
  defaultMonth,
  ...rest
}) => {
  return (
    <>
      <FieldLabel display={'flex'} alignItems={'center'}>
        {fieldLabel}
        {isMandatory && <sup style={{ color: TEXT_COLOR_THEME.error }}>*</sup>}
        {tooltipInfo && (
          <Tooltip title={tooltipInfo}>
            <InfoOutlined style={{ marginLeft: 10 }} />
          </Tooltip>
        )}
      </FieldLabel>
      <Controller
        control={control}
        name={`${name}`}
        render={({ field, fieldState }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: fieldState.error ? TEXT_COLOR_THEME.error : '',
                },
              }}
              {...field}
              onYearChange={(newValue) => {
                field.onChange(
                  defaultDay ? replaceDay(newValue, defaultDay, defaultMonth) : newValue,
                );
              }}
              slotProps={{ textField: { size: 'small' } }}
              format='dd/MM/yyyy'
              value={field.value ? new Date(field.value) : ''}
              {...rest}
              onChange={(newValue) => {
                field.onChange(newValue);
              }}
            />
            {fieldState.error && (
              <FormHelperText style={{ color: TEXT_COLOR_THEME.error }}>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </LocalizationProvider>
        )}
      />
    </>
  );
};

export default CustomeDatepicker;
