import { FC } from 'react';
import CustomModal from '../../../../common/component/CustomModal';
import { Box, Grid } from '@mui/material';
import { useMultiCompletedTrade } from './hook';
import TeamTradeForm from './TeamTradeForm';
import { PrimaryButton } from '../../../../common/component/Button';
import { Add } from '@mui/icons-material';
import { MultiTeamType } from './type';
import MultipleTransactionImpact from './MultipleTransactionImpact';
import Banner from '../../../../common/component/Banner';
import { ScenarioTradeType } from '../../../scenarioplanning/type';

type MultipleCompletedTradeProps = {
  closeTransactionModal: () => void;
  scenarioTradeInfo?: ScenarioTradeType;
  refreshList?: () => void;
};
const MultipleCompletedTrade: FC<MultipleCompletedTradeProps> = ({
  closeTransactionModal,
  scenarioTradeInfo,
  refreshList,
}) => {
  const {
    teamDataDropdown,
    handleSubmit,
    onSubmit,
    control,
    errors,
    register,
    currentProject,
    setValue,
    teams,
    isLoading,
    transctionImpactData,
    flattenPicksTrading,
  } = useMultiCompletedTrade(scenarioTradeInfo, refreshList);

  const removeTeam = (itemId: string) => {
    setValue(
      'team',
      teams.filter((_: MultiTeamType, index: number) => _.id !== itemId),
    );
  };

  const getMultipleTradeModalTitle = () => {
    if (scenarioTradeInfo?.transactionData) {
      return 'Update Scenario Multiple Completed Trade';
    }
    if (scenarioTradeInfo) {
      return 'Scenario Multiple Completed Trade';
    }
    return 'Multiple Completed Trade';
  };
  return (
    <CustomModal
      saveLoading={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
      modalSize={'lg'}
      handleClose={closeTransactionModal}
      modalTitle={getMultipleTradeModalTitle()}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box display={'flex'} gap={'40px'} alignItems={'center'} justifyContent={'end'}>
            <Box width={'70%'}>
              <Banner text='To use the Multi Team Trade, select each team and then enter the picks or players that they are receiving' />
            </Box>
            <Box width={'30%'}>
              <PrimaryButton
                onClick={() =>
                  setValue('team', [
                    ...teams,
                    {
                      id: Math.random().toString(),
                      picksIn: [],
                      playersIn: [
                        {
                          clubId: '',
                          id: Math.random().toString(),
                          playerName: '',
                        },
                      ],
                      teamId: '',
                    },
                  ])
                }
                startIcon={<Add />}
                title='Click to Add another Team'
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} mt={'30px'}>
          <Box
            gap={'40px'}
            display={'inline-flex'}
            pr={'40px'}
            overflow={'auto'}
            flexWrap={'nowrap'}
          >
            {teams.map((item, index) => (
              <Box key={index} width={'300px'}>
                <TeamTradeForm
                  control={control}
                  errors={errors}
                  removeTeam={() => removeTeam(item.id)}
                  currentProject={currentProject}
                  index={index}
                  register={register}
                  setValue={setValue}
                  teamOption={teamDataDropdown}
                  key={index}
                  flattenPicksTrading={flattenPicksTrading}
                  name={`team[${index}]`}
                  title={`Team ${index + 1}`}
                />
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} mt={'30px'}>
          <Box
            gap={'40px'}
            display={'inline-flex'}
            pr={'40px'}
            minHeight={'500px'}
            overflow={'auto'}
            flexWrap={'nowrap'}
          >
            {transctionImpactData.map((item, index) => (
              <Box key={index} width={'300px'}>
                <MultipleTransactionImpact impactData={item} />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default MultipleCompletedTrade;
