import { FC, useMemo, useState } from 'react';
import { PicksOptionType } from '../../type';
import { Box, Chip } from '@mui/material';
import TradingOptionModal from './TradingOptionModal';
import { FieldLabel } from '../../../../common/component/Text';
import { transformTeamPicks } from './util';

type TradingOptionPickerProps = {
  value: string[];
  setValue: (value: string) => void;
  flattenPicksTrading: PicksOptionType[];
};

const TradingOptionPicker: FC<TradingOptionPickerProps> = ({
  value,
  setValue,
  flattenPicksTrading,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const pickList = useMemo(() => transformTeamPicks(flattenPicksTrading), [flattenPicksTrading]);

  return (
    <Box>
      <FieldLabel>Select Picks Trading In:</FieldLabel>
      <Box
        width={'100%'}
        border={'1px rgba(0, 0, 0, 0.23) solid'}
        minHeight={'40px'}
        borderRadius={'10px'}
        onClick={handleClick}
        display={'flex'}
        flexWrap={'wrap'}
        gap={'10px'}
        padding={'10px'}
      >
        {value.map((item) => (
          <Chip
            key={item}
            onDelete={() => setValue(item)}
            label={flattenPicksTrading.find((pick) => pick.value === item)?.display}
            variant='outlined'
          />
        ))}
      </Box>
      {open && (
        <TradingOptionModal
          anchorEl={anchorEl}
          value={value}
          handleClose={handleClose}
          setValue={setValue}
          transformPicksList={pickList}
        />
      )}
    </Box>
  );
};

export default TradingOptionPicker;
