import { baseQuesryWithErrorToast } from '..';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  bulkCreationRoasterAllocationApiUrl,
  fetchClubSummaryApiUrl,
  roasterAllocationYearTeamApiUrl,
  validateBulkPlayerApitUrl,
  validatePlayerApitUrl,
} from '../../../constant/ApiConstant';
import { BulkContractFormType, EditPlayerInfoType } from '../../../container/smartlist/type';
import {
  reversePlayerValidate,
  reverseTransformBulkPlayerValidate,
  reverseTransformBulkRoasterAllocationData,
} from './util';

export const clubSummaryServiceApi = createApi({
  reducerPath: 'clubsummarysearvice',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQuesryWithErrorToast,
  endpoints: (builder) => ({
    getClubSummaryInfo: builder.query({
      query: ({ orgId, teamId }: { orgId: string; teamId: string }) =>
        fetchClubSummaryApiUrl(orgId, teamId),
    }),
    roasterAllocationYearTeamInfo: builder.query({
      query: ({ orgId, teamId }: { orgId: string; teamId: string }) =>
        roasterAllocationYearTeamApiUrl(orgId, teamId),
    }),
    bulkRoasterAllocation: builder.mutation({
      query: ({
        data,
        orgId,
        teamId,
      }: {
        data: BulkContractFormType;
        orgId: string;
        teamId: string;
      }) => ({
        url: bulkCreationRoasterAllocationApiUrl(orgId),
        method: 'POST',
        body: reverseTransformBulkRoasterAllocationData(data, teamId),
      }),
    }),
    validatePlayer: builder.mutation({
      query: ({
        data,
        orgId,
        teamId,
      }: {
        data: EditPlayerInfoType;
        orgId: string;
        teamId: string;
      }) => ({
        url: validatePlayerApitUrl(orgId),
        method: 'POST',
        body: reversePlayerValidate(data, teamId),
      }),
    }),
    validateBulkPlayer: builder.mutation({
      query: ({
        data,
        orgId,
        teamId,
      }: {
        data: EditPlayerInfoType[];
        orgId: string;
        teamId: string;
      }) => ({
        url: validateBulkPlayerApitUrl(orgId),
        method: 'POST',
        body: reverseTransformBulkPlayerValidate(data, teamId),
      }),
    }),
  }),
});

export const {
  useLazyGetClubSummaryInfoQuery,
  useLazyRoasterAllocationYearTeamInfoQuery,
  useBulkRoasterAllocationMutation,
  useValidatePlayerMutation,
  useValidateBulkPlayerMutation,
} = clubSummaryServiceApi;
