import { Box, Collapse, TableCell, TableRow, Typography } from '@mui/material';
import { IoMdTrash, IoMdWarning } from 'react-icons/io';
import { TEXT_COLOR_THEME } from '../../../theme/color';
import { EditPlayerInfoType, ValidateConflictType } from '../type';
import { Control, useWatch } from 'react-hook-form';
import CustomeDatepicker from '../../../common/fields/reacthookfield/CustomeDatepicker';
import { add, sub } from 'date-fns';
import CustomSelect from '../../../common/fields/reacthookfield/CustomSelect';
import { OptionType } from '../../../common/fields/type';
import { PrimaryOutlineButton, PrimaryTextButton } from '../../../common/component/Button';
import { useValidatePlayerMutation } from '../../../store/service/clubsummaryservice';
import { useMemo } from 'react';
import { transformConflictData } from '../util';

type PlayerCardInfoProps = {
  playerInfo: EditPlayerInfoType;
  index: number;
  control: Control<any, any>;
  contractOptions: OptionType[];
  handleDelete: (playerId: number) => void;
  orgId: string;
  teamId: string;
  validationError?: ValidateConflictType | null;
};
const PlayerCardInfo = ({
  playerInfo,
  index,
  control,
  contractOptions,
  handleDelete,
  orgId,
  teamId,
  validationError,
}: PlayerCardInfoProps) => {
  const contractType = useWatch({
    control,
    name: `playerInfo[${index}].contractType`,
  });
  const [validatePlayer, { isLoading, data }] = useValidatePlayerMutation();

  const conflictData = useMemo(() => {
    return validationError || transformConflictData(data);
  }, [data, validationError]);

  const handleValidateError = () => {
    validatePlayer({
      data: playerInfo,
      orgId,
      teamId,
    });
  };

  return (
    <>
      <TableRow
        sx={{
          '&:hover': { bgcolor: 'grey.50' },
          borderLeft:
            conflictData && !conflictData.valid ? `4px solid ${TEXT_COLOR_THEME.error}` : 'none',
        }}
      >
        <TableCell>{playerInfo.playerName}</TableCell>
        <TableCell>
          <CustomeDatepicker
            control={control}
            fieldLabel={''}
            name={`playerInfo[${index}].startSeason`}
            views={['year']}
            format='yyyy'
            defaultDay={1}
            defaultMonth={1}
            minDate={sub(new Date(), {
              years: 10,
            })}
            maxDate={add(new Date(), {
              years: 10,
            })}
          />
        </TableCell>
        <TableCell>
          <CustomeDatepicker
            control={control}
            fieldLabel={''}
            name={`playerInfo[${index}].endSeason`}
            views={['year']}
            format='yyyy'
            defaultDay={1}
            defaultMonth={1}
            minDate={sub(new Date(), {
              years: 10,
            })}
            maxDate={add(new Date(), {
              years: 10,
            })}
          />
        </TableCell>
        <TableCell>
          <CustomSelect
            options={contractOptions}
            id='contractType'
            name={`playerInfo[${index}].contractType`}
            value={contractType}
            fullWidth
            control={control}
            key={contractType}
            variant='outlined'
            label={''}
          />
        </TableCell>
        <TableCell>
          <Box display={'flex'} gap={'10px'}>
            <PrimaryOutlineButton
              style={{
                borderRadius: '10px',
              }}
              isLoading={isLoading}
              title='Validate'
              onClick={() => handleValidateError()}
            />
            <PrimaryTextButton
              onClick={() => handleDelete(playerInfo.playerId)}
              title='Delete'
              startIcon={<IoMdTrash color={TEXT_COLOR_THEME.error} />}
            />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={conflictData && !conflictData.valid ? true : false}>
            <Box display={'flex'} gap={'10px'} alignItems={'center'}>
              <IoMdWarning
                style={{
                  fontSize: 24,
                }}
                color={TEXT_COLOR_THEME.error}
              />
              <Box display={'flex'} flexWrap={'wrap'}>
                {conflictData?.conflicts?.map((conflict, idx) => (
                  <Box
                    border={'1px #ccc solid'}
                    p={'10px'}
                    m={'10px'}
                    borderRadius={'5px'}
                    bgcolor={TEXT_COLOR_THEME.errorLight}
                    key={`conflict-${conflict.type}-${idx}-${playerInfo.playerId}`}
                  >
                    <Typography fontSize={'12px'} fontWeight={'bold'}>
                      {conflict.type}
                    </Typography>
                    <Typography fontSize={'12px'}>{conflict.typeDescription}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PlayerCardInfo;
