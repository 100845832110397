import { object, string } from 'yup';
import { OptionType } from '../../common/fields/type';
import { transformFlagTooltip } from '../header/util';
import { CompletedTradeType, PicksOptionType } from '../transaction/type';
import {
  PlayersType,
  ScenarioTradeType,
  ScenarioType,
  TransactionAcademyBidMatchDetailsType,
  TransactionDetailsType,
  TransactionMultiTeamTradeDetailsType,
  TransactionType,
} from './type';

export const transformTeamNameDropdownOption = (data?: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.map((item: any) => ({
    value: item.id,
    label: item.TeamNames,
  }));
};

export const transactionScenarioMenuOption = [
  {
    id: 'transaction_1',
    value: 'completed_trade',
    label: 'Trade',
  },
  {
    id: 'transaction_6',
    value: 'mulitple_completed_trade',
    label: 'Multi Team Trade',
  },
  {
    id: 'transaction_3',
    value: 'priority_pick',
    label: 'Priority Pick',
  },
  {
    id: 'transaction_4',
    value: 'free_agent_compensation',
    label: 'Free agent compensation',
  },
  {
    id: 'transaction_7',
    value: 'father_son_bid_match',
    label: 'Father Son Bid Match',
  },
  {
    id: 'transaction_5',
    value: 'academy_bid',
    label: 'Academy Bid Match',
  },
  {
    id: 'transaction_2',
    value: 'delete_trade',
    label: 'Enter Draft Mode',
  },
];

const transfromTransactionDetails = (data: any): TransactionDetailsType => {
  return {
    pickid1: data.pickid1,
    pickid2: data.pickid2,
    player1: data.player1,
    player2: data.player2,
    team1: data.teamid1 || '',
    team2: data.teamid2 || '',
    teamid: data.teamid || '',
  };
};

const transfromTransactionAcademyBidDetails = (
  data: any,
): TransactionAcademyBidMatchDetailsType => {
  return {
    academyTeam: data.academy_team,
    pickId: data.pickid,
    playerId: data.playerid,
    transactionDescription: data.transaction_description,
  };
};

const transformPicksIn = (data: any): PicksOptionType[] => {
  return data.map(
    (item: any) =>
      ({
        current_owner: item.current_owner,
        display: item.display,
        label: item.label,
        overall_pick: item.overall_pick,
        pick_status: item.pick_status,
        unique: item.unique,
        value: item.value,
        year: '',
      } as PicksOptionType),
  );
};

const transformPlayersIn = (data: any): PlayersType[] => {
  return data.map(
    (item: any) =>
      ({
        playerId: item.player_id,
        teamId: item.team_id,
      } as PlayersType),
  );
};

const transformultiTransactionDetails = (data: any): TransactionMultiTeamTradeDetailsType[] => {
  return data.map(
    (item: any) =>
      ({
        picksIn: transformPicksIn(item.picks_in),
        playersIn: transformPlayersIn(item.players_in),
        teamId: item.team_id,
      } as TransactionMultiTeamTradeDetailsType),
  );
};

const transformDetailAccordigToType = (
  type: string,
  data: any,
):
  | TransactionAcademyBidMatchDetailsType
  | TransactionDetailsType
  | TransactionMultiTeamTradeDetailsType[] => {
  if (type === 'Academy Bid') {
    return transfromTransactionAcademyBidDetails(data);
  }
  if (type === 'Multi Team Trade') {
    return transformultiTransactionDetails(data);
  }
  return transfromTransactionDetails(data);
};
const transformTransactionListType = (data: any): TransactionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        dateTime: item.transaction_dateTime,
        description: item.transaction_description,
        details: transformDetailAccordigToType(item.transaction_type, item.transaction_details),
        id: item.transaction_id,
        transactionNumber: item.transaction_number,
        lastTransaction: item.last_transaction,
        tooltip: transformFlagTooltip(item.tooltip),
        type: item.transaction_type,
      } as TransactionType),
  );
};

export const transformScenarioVisualisation = (data: any): ScenarioType[] => {
  if (!data) {
    return [];
  }

  return data.scenarios.map(
    (item: any) =>
      ({
        id: item.scenario_number,
        transactionList: transformTransactionListType(item.transactions),
      } as ScenarioType),
  );
};

const transformSceanrioCompleteTrade = (data: any): CompletedTradeType => {
  return {
    team1: {
      name: data.details.team1,
      playerName: data.details.player1.map((item: any) => item.player_id).join(', '),
      tradingOut: data.details.pickid1.map((item: any) => item.value),
    },
    team2: {
      name: data.details.team2,
      playerName: data.details.player2.map((item: any) => item.player_id).join(', '),
      tradingOut: data.details.pickid2.map((item: any) => item.value),
    },
  };
};
export const CompletedTradeScenarioInitialValue = (
  scenarioTradeData?: ScenarioTradeType,
): CompletedTradeType => {
  if (scenarioTradeData?.transactionData) {
    return transformSceanrioCompleteTrade(scenarioTradeData.transactionData);
  }
  return {
    team1: {
      name: '',
      tradingOut: [],
      playerName: '',
    },
    team2: {
      name: '',
      tradingOut: [],
      playerName: '',
    },
  };
};

export const CompletionTradeScenarioValidationSchema = object().shape({
  team1: object().shape({
    name: string().required('Please select Team name'),
  }),
  team2: object().shape({
    name: string().required('Please select Team name'),
  }),
});

export const reorderScenarios = (list: ScenarioType[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
