import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import { Header2 } from '../../../common/component/Text';
import { StyledLogo } from '../../dashboard/style';
import { TeamFlagOptions } from '../../../constant/OptionConstant';
import { YearData } from '../type';
import { BiMinus, BiPlus } from 'react-icons/bi';

type RosterCardProps = {
  setRosterAmount: (rosterId: number, rosterYear: string, amount: number) => void;
  newRosterList: YearData[];
};
const RosterCard: FC<RosterCardProps> = ({ newRosterList, setRosterAmount }) => {
  return (
    <Box>
      {newRosterList[0].teamInfo.map((item, index) => {
        return (
          <Box
            borderBottom={'1px #efefef solid'}
            px={'10px'}
            mt={'12px'}
            display={'flex'}
            key={`team-id-${item.teamId}-${index}`}
          >
            <Box flex={1}>
              <Header2>{index + 1}</Header2>
            </Box>
            <Box flex={2}>
              <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                <StyledLogo
                  src={TeamFlagOptions.find((flag) => flag.id === item.teamId)?.flagIcon || ''}
                  style={{ height: 30, width: 30 }}
                  alt={item.teamId.toString()}
                />
                <Header2>
                  {TeamFlagOptions.find((flag) => flag.id === item.teamId)?.shortName}
                </Header2>
              </Box>
            </Box>
            {newRosterList.map((roster, index) => {
              const findTeam = roster.teamInfo.find((team) => team.teamId === item.teamId);
              return (
                <Box flex={2} key={`${roster.year}-${index}`}>
                  <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() =>
                        setRosterAmount(
                          item.teamId,
                          roster.year.toString(),
                          findTeam!.rosterSpotsAvailable - 1,
                        )
                      }
                      disabled={findTeam!.rosterSpotsAvailable <= 1}
                    >
                      <BiMinus />
                    </IconButton>
                    <Header2>{findTeam!.rosterSpotsAvailable}</Header2>
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() =>
                        setRosterAmount(
                          item.teamId,
                          roster.year.toString(),
                          findTeam!.rosterSpotsAvailable + 1,
                        )
                      }
                      disabled={findTeam!.rosterSpotsAvailable >= 10}
                    >
                      <BiPlus />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default RosterCard;
