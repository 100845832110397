import { ProjectListType, ProjectType, RosterListType, YearData } from './type';
import { OptionType } from '../../common/fields/type';
import { object, string } from 'yup';

export const transformProjectList = (data: any): ProjectListType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (item: any, index: number) =>
      ({
        file: item.file,
        id: index + 1,
        projectId: item.id,
        projectDesc: item.project_desc,
        projectName: item.project_name,
        teamid: item.teamid,
        userId: item.user_id,
        year: item.year,
        bidDiscounts: item?.bidding_discount_type || '',
        draftValueIndex: item?.draft_value_index_type || '',
        draftType: item.draft_type,
      } as ProjectListType),
  );
};

export const createProjectInitialValue = (projectInfo?: ProjectListType): ProjectType => ({
  projectName: projectInfo?.projectName || '',
  projectDescription: projectInfo?.projectDesc || '',
  draftYear: projectInfo?.year || '',
  team: parseInt(projectInfo?.teamid || '0') || '',
  bidDiscounts: projectInfo?.bidDiscounts || '',
  draftValueIndex: projectInfo?.draftValueIndex || '',
  draftType: projectInfo?.draftType || '',
});

export const transformShowDraftOption = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.map((item: any) => ({
    value: item.draft_type,
    label: item.display_name,
    unique: item.year,
  }));
};

const transformByYear = (originalData: any[]): YearData[] => {
  // Get unique years
  const years = Array.from(new Set(originalData.map((item) => item.year)));

  // Transform data for each year
  return years.map((year) => ({
    year,
    teamInfo: originalData
      .filter((item) => item.year === year)
      .map((item) => ({
        teamId: item.team_id,
        rosterSpotsAvailable: item.roster_spots_available,
      })),
  }));
};

export const transformRosterData = (data: any): RosterListType => {
  if (!data) {
    return {
      rosterList: [],
    };
  }

  return {
    rosterList: transformByYear(data),
  };
};

export const projectValidationSchema = object().shape({
  projectDescription: string().required('Please enter project description'),
  draftType: string().required('Please select draft type'),
  projectName: string().required('Please enter project name'),
  team: string().required('Please select team'),
  bidDiscounts: string(),
  draftValueIndex: string(),
});
