import { FC, useCallback } from 'react';
import { YearData } from '../type';
import { Box, Grid } from '@mui/material';
import { ColumnHeaderText } from '../../../common/component/Text';
import RosterCard from './RosterCard';

type RosterListProps = {
  isLoading: boolean;
  setRosterAmount: (rosterId: number, rosterYear: string, amount: number) => void;
  rosterList: YearData[];
};
const RosterList: FC<RosterListProps> = ({ setRosterAmount, rosterList }) => {
  const rosterCard = useCallback(
    (
      item: YearData[],
      setRosterAmount: (rosterId: number, rosterYear: string, amount: number) => void,
    ) => <RosterCard newRosterList={item} setRosterAmount={setRosterAmount} />,
    [],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          borderRadius={'10px 10px 0px 0px'}
          px={'10px'}
          py={'16px'}
          bgcolor={'#efefef'}
        >
          <Box flex={1}>
            <ColumnHeaderText>#</ColumnHeaderText>
          </Box>
          <Box flex={2}>
            <ColumnHeaderText>Team</ColumnHeaderText>
          </Box>
          {rosterList.map((item, index) => (
            <Box key={index} flex={2}>
              <ColumnHeaderText>{item.year}</ColumnHeaderText>
            </Box>
          ))}
        </Box>
        {rosterList.length > 0 && rosterCard(rosterList, setRosterAmount)}
      </Grid>
    </Grid>
  );
};

export default RosterList;
