/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import {
  useCreateMultiTransactionImpactDataMutation,
  useDeleteTransactionMutation,
  useGetCompleteMultiTransactionImpactDataMutation,
  useGetShowTeamQuery,
  useLazyGetAllPickQuery,
  useLazyGetAllScenarioPickQuery,
} from '../../../../store/service';
import {
  transformAcademyTeamNameDropdownOption,
  transformMulitNestedPickDataOption,
} from '../../util';
import { useAppSelector } from '../../../../store/hooks';
import { useCurrentProject } from '../../../../store/header/selector';
import { useForm, useWatch } from 'react-hook-form';
import { MultiTradeType } from './type';
import {
  MultiTradeInitialValue,
  reverseMultiImpactTransformData,
  transformMultiTradeImpact,
} from './util';
import { useDispatch } from 'react-redux';
import {
  setSelectedTransactionModal,
  setUploadCsvSelectedTransactionTypeModal,
} from '../../../../store/header';
import { useGetUserDetail } from '../../../../store/auth/selector';
import {
  ScenarioTradeType,
  TransactionMultiTeamTradeDetailsType,
} from '../../../scenarioplanning/type';
import {
  useCreateScenarioMultiTransactionImpactDataMutation,
  useGetScenarioCompleteMultiTransactionImpactDataMutation,
} from '../../../../store/service/scenarioservice';
import { flatten } from 'lodash';
import { PicksOptionType } from '../../type';

export const useMultiCompletedTrade = (
  scenarioTradeInfo?: ScenarioTradeType,
  refreshList?: () => void,
) => {
  const currentProject = useAppSelector(useCurrentProject);
  const { data: showTeamData } = useGetShowTeamQuery('');
  const [getPicks, { data: allPicksData }] = useLazyGetAllPickQuery();
  const [getAllScenarioPicks, { data: allScenarioPicksData }] = useLazyGetAllScenarioPickQuery();

  useEffect(() => {
    scenarioTradeInfo
      ? getAllScenarioPicks({
          projectId: currentProject,
          scenarioId: scenarioTradeInfo.scenarioId,
          transactionNo: (scenarioTradeInfo.transactionLength - 1).toString(),
        })
      : getPicks(Number(currentProject));
  }, [scenarioTradeInfo]);

  const picksDropdownOption = useMemo(
    () => transformMulitNestedPickDataOption(allPicksData),
    [allPicksData],
  );

  const flattenTradePicksOption = useMemo(() => {
    const option: PicksOptionType[] = [];
    picksDropdownOption.forEach((item) => {
      option.push(...flatten(item.picksOption));
    });
    return option;
  }, [picksDropdownOption]);

  const scenarioPickDropdownOptions = useMemo(
    () => transformMulitNestedPickDataOption(allScenarioPicksData),
    [allScenarioPicksData],
  );

  const flattenScenarioPicksOption = useMemo(() => {
    const option: PicksOptionType[] = [];
    scenarioPickDropdownOptions.forEach((item) => {
      option.push(...flatten(item.picksOption));
    });
    return option;
  }, [scenarioPickDropdownOptions]);

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: MultiTradeInitialValue(
      scenarioTradeInfo?.transactionData?.details as TransactionMultiTeamTradeDetailsType[],
    ),
  });

  const [getCompleteMultiTransactionImpact, { data: transactionImpactData }] =
    useGetCompleteMultiTransactionImpactDataMutation();

  const [getScenarioCompleteMultiTransactionImpact, { data: scenarioTransactionImpactData }] =
    useGetScenarioCompleteMultiTransactionImpactDataMutation();
  const [createMultiTransactionImpactData, { isLoading: createMultiTradeLoading }] =
    useCreateMultiTransactionImpactDataMutation();

  const [createScenarioMultiTransactionImpactData, { isLoading: createScenarioLoading }] =
    useCreateScenarioMultiTransactionImpactDataMutation();

  const teamDataDropdown = useMemo(
    () => transformAcademyTeamNameDropdownOption(showTeamData?.data),
    [showTeamData],
  );
  const dispatch = useDispatch();
  const teams = useWatch({
    control,
    name: 'team',
  });

  useEffect(() => {
    let error = false;

    teams.forEach((item) => {
      if (item.teamId === '') {
        error = true;
      }
    });
    if (!error) {
      if (scenarioTradeInfo) {
        scenarioPickDropdownOptions.length > 0 &&
          getScenarioCompleteMultiTransactionImpact({
            data: reverseMultiImpactTransformData(teams, scenarioPickDropdownOptions),
            projectId: currentProject,
            scenarioInfo: {
              ...scenarioTradeInfo,
              transactionLength: scenarioTradeInfo.transactionLength - 1,
            },
          });
        return;
      }
      getCompleteMultiTransactionImpact({
        data: reverseMultiImpactTransformData(teams, picksDropdownOption),
        projectId: currentProject,
      });
    }
  }, [teams, scenarioPickDropdownOptions]);

  const { userInfo } = useAppSelector(useGetUserDetail);
  const [deleteTransaction] = useDeleteTransactionMutation();

  const onSubmit = (value: MultiTradeType) => {
    if (scenarioTradeInfo?.transactionData) {
      deleteTransaction({
        projectId: currentProject,
        scenarioId: scenarioTradeInfo.scenarioId.toString(),
        transactionId: (scenarioTradeInfo.transactionLength - 1).toString(),
      }).then(() => {
        scenarioTradeInfo &&
          createScenarioMultiTransactionImpactData({
            data: reverseMultiImpactTransformData(
              value.team,
              scenarioTradeInfo ? scenarioPickDropdownOptions : picksDropdownOption,
            ),
            projectId: currentProject,
            scenarioInfo: {
              ...scenarioTradeInfo,
              transactionLength: scenarioTradeInfo.transactionLength - 1,
            },
          }).then((res: any) => {
            if (!res.error) {
              refreshList && refreshList();
            }
          });
      });
      return;
    }
    if (scenarioTradeInfo) {
      createScenarioMultiTransactionImpactData({
        data: reverseMultiImpactTransformData(
          value.team,
          scenarioTradeInfo ? scenarioPickDropdownOptions : picksDropdownOption,
        ),
        projectId: currentProject,
        scenarioInfo: {
          ...scenarioTradeInfo,
          transactionLength: scenarioTradeInfo.transactionLength - 1,
        },
      }).then((res: any) => {
        if (!res.error) {
          refreshList && refreshList();
        }
      });
      return;
    }

    createMultiTransactionImpactData({
      data: reverseMultiImpactTransformData(
        value.team,
        scenarioTradeInfo ? scenarioPickDropdownOptions : picksDropdownOption,
      ),
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        dispatch(setSelectedTransactionModal(''));
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Multi Team Trade',
              payload: reverseMultiImpactTransformData(
                value.team,
                scenarioTradeInfo ? scenarioPickDropdownOptions : picksDropdownOption,
              ),
            }),
          );
      }
    });
  };

  const transctionImpactData = useMemo(
    () => transformMultiTradeImpact(transactionImpactData),
    [transactionImpactData],
  );

  const transctionScenarioImpactData = useMemo(
    () => transformMultiTradeImpact(scenarioTransactionImpactData),
    [scenarioTransactionImpactData],
  );

  return {
    currentProject,
    setValue,
    teams,
    control,
    errors,
    register,
    handleSubmit,
    teamDataDropdown,
    onSubmit,
    isLoading: createScenarioLoading || createMultiTradeLoading,
    transctionImpactData: scenarioTradeInfo ? transctionScenarioImpactData : transctionImpactData,
    flattenPicksTrading: scenarioTradeInfo ? flattenScenarioPicksOption : flattenTradePicksOption,
  };
};
