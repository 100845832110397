import { Box, Grid, Menu, Tooltip } from '@mui/material';
import { FC } from 'react';
import { ColumnHeaderText, CompactTag, Header2 } from '../../../../common/component/Text';
import { TeamFlagOptions } from '../../../../constant/OptionConstant';
import { TeamPicks } from './type';
import { PicksOptionType } from '../../type';

type TradingOptionModalProps = {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  value: string[];
  setValue: (value: string) => void;
  transformPicksList: TeamPicks[];
};
const TradingOptionModal: FC<TradingOptionModalProps> = ({
  anchorEl,
  handleClose,
  setValue,
  value,
  transformPicksList,
}) => {
  const getLabelColor = (option: PicksOptionType) => {
    if (option.year.toString() !== new Date().getFullYear().toString()) {
      return value.includes(option.value) ? '#fff' : '#737373';
    }
    return value.includes(option.value) ? '#fff' : '#189a18';
  };

  const getBackgroundColor = (option: PicksOptionType) => {
    if (!value.includes(option.value)) {
      return 'transparent';
    }
    if (option.year.toString() !== new Date().getFullYear().toString()) {
      return '#737373';
    }
    return '#189a18';
  };

  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open
      PaperProps={{
        style: {
          width: 900,
        },
      }}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <Grid container height={'500px'} overflow={'scroll'}>
        <Grid item xs={12}>
          <Box
            p={'10px'}
            border={'1px solid rgba(224, 224, 224, 1)'}
            bgcolor={'#efefef'}
            py={'20px'}
            borderRadius={'10px'}
            style={{ borderRadius: '10px 10px 0px 0px' }}
          >
            <ColumnHeaderText>CURRENT PICKS</ColumnHeaderText>
          </Box>
          <Grid item xs={12}>
            {transformPicksList.map((item, index) => (
              <Box
                key={`pick-list-${item.teamId}-${index}`}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                padding={'20px'}
                borderTop={0}
                gap={'30px'}
                border={'1px rgba(224, 224, 224, 1) solid'}
              >
                <Header2 width={'60px'}>
                  {TeamFlagOptions.find((flag) => flag.id === Number(item.teamId))?.shortName || ''}
                </Header2>
                <Box mt={'10px'}>
                  {item.pickList.map((pick, index) => (
                    <Header2
                      gap={'5px'}
                      key={`picksList-${pick.year}-${index}`}
                      display={'flex'}
                      flexWrap={'wrap'}
                      mt={1}
                    >
                      {pick.year}
                      {pick.picks.map((option, index) => (
                        <Tooltip key={`picks-${option.unique}-${index}`} title={option.label}>
                          <CompactTag
                            gap={'5px'}
                            display={'flex'}
                            onClick={() => setValue(option.value)}
                            alignItems={'center'}
                            key={option.unique}
                            textsize={'12px'}
                            textAlign={'center'}
                            bgcolor={getBackgroundColor(option)}
                            fontcolor={getLabelColor(option)}
                          >
                            {option.display}
                          </CompactTag>
                        </Tooltip>
                      ))}
                    </Header2>
                  ))}
                </Box>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Menu>
  );
};

export default TradingOptionModal;
